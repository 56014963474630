import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { Wrapper } from "./style";

const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
    filterKey: "today",
  },
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    filterKey: "yesterday",
  },
  {
    label: "Last 7 days",
    value: [dayjs().subtract(7, "days"), dayjs()],
    filterKey: "yesterday",
  },
  {
    label: "This week",
    value: [dayjs().startOf("week"), dayjs()],
    filterKey: "last_week",
  },
  {
    label: "Last 30 days",
    value: [dayjs().add(-30, "d"), dayjs()],
    filterKey: "yesterday",
  },
  {
    label: "This month",
    value: [dayjs().startOf("month"), dayjs()],
    filterKey: "yesterday",
  },
  {
    label: "Last month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
    filterKey: "yesterday",
  },
  {
    label: "Year to date",
    value: [dayjs().startOf("year"), dayjs()],
    filterKey: "year_to_date",
  },
  {
    label: "Life time",
    value: [dayjs().subtract(5, "year").startOf("month"), dayjs()],
    filterKey: "yesterday",
  },
];

const RangePickerESF = (props) => {
  const { presets, id, value, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  const getformat = (value) => {
    return moment(value).format("YYYY-MM-DD");
  };

  useEffect(() => {
    const element = document
      .getElementsByClassName(id)?.[0]
      ?.getElementsByClassName("ant-picker-presets");

    if (element && isOpen) {
      const isActive = (presets || rangePresets || [])
        ?.map((d) => {
          const presetsDates = d?.value;
          return {
            ...d,
            selected:
              getformat(presetsDates?.[0]?.$d) === getformat(value?.[0]?.$d) &&
              getformat(presetsDates?.[1]?.$d) === getformat(value?.[1]?.$d),
          };
        })
        .findIndex((d) => d?.selected === true);
      const nodes = element?.[0]?.childNodes?.[0]?.childNodes;
      let rangeList =
        isActive === -1 ? nodes?.[nodes?.length - 1] : nodes?.[isActive];
      const pastSelected = document
        .getElementsByClassName(id)?.[0]
        ?.querySelectorAll(".antd_selected_presets_date");

      console.log(pastSelected, "pastSelected");
      if (pastSelected?.length !== 0) {
        pastSelected?.[0]?.classList.remove("antd_selected_presets_date");
      }
      rangeList?.classList.add("antd_selected_presets_date");
    }
  }, [isOpen]);

  return (
    <Wrapper>
      <RangePicker
        presets={presets || rangePresets || []}
        value={value}
        id={id}
        popupClassName={id}
        onOpenChange={() => setIsOpen(!isOpen)}
        size="large"
        {...rest}
      />
    </Wrapper>
  );
};

export default RangePickerESF;
