import { Checkbox, DatePicker, Input, Select, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import {
  DateFormat,
  DisplayNotification,
  PhotoUrl,
} from "../../../../../config";
import dayjs from "dayjs";
import moment from "moment";

export default function ({
  show,
  onHide,
  data,
  portfolioList,
  selected_marketplace,
  selected_currency_sign,
}) {
  const [sending, setSending] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [campaignRowId, setCampaignRowId] = useState("");
  const [old_budget, set_old_budget] = useState("");
  const [campaignData, setCampaignData] = useState({
    new_budget: "",
    portfolio_id: null,
    start_date: "",
    end_date: null,
    name: "",
    state: "",
    bidding_strategy: "",
    top_of_search_placement: "",
    product_pages_placement: "",
  });
  useEffect(() => {
    if (Object.entries(data).length > 0) {
      console.log(data, "-data");
      set_old_budget(data?.budget_amount);
      setCampaignId(data?.campaign_id);
      setCampaignRowId(data?.id);
      setCampaignData({
        new_budget: data?.budget_amount,
        portfolio_id: data?.portfolio_id,
        start_date: data?.start_date,
        end_date: data?.end_date || null,
        name: data?.campaign_name,
        state: data?.state,
        bidding_strategy: data?.bid_strategy,
        top_of_search_placement: data?.top_of_search_placement,
        product_pages_placement: data?.product_pages_placement,
        advertising_type: data?.advertising_type,
      });
    }
  }, [data]);

  const UpdateCampaignDataList = (key, value) => {
    const common_data = {
      ...campaignData,
      [key]: value,
    };
    setCampaignData(common_data);
  };

  const UpdateCampaign = async () => {
    const campaignJson = {
      ...campaignData,
      campaign_id: data?.campaign_id,
      old_budget: old_budget,
      marketplace_id: selected_marketplace,
    };
    const response = await MakeApiCall(
      `advertising/update-campaign/${campaignRowId}`,
      `PUT`,
      campaignJson,
      true
    );
    if (response?.status) {
      setSending(false);
      DisplayNotification("success", "Success", response?.message);
      onHide();
    } else {
      setSending(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "350px" }}>
            <div className="row">
              <div className="col-12 mb-7">
                <label className="fw-bold fs-6 required">Campaign Name</label>
                <Input
                  style={{ width: "100%" }}
                  value={campaignData?.name}
                  onChange={(e) => {
                    UpdateCampaignDataList("name", e.target.value);
                  }}
                  size="large"
                />
              </div>
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">Budget</label>
                <Input
                  value={campaignData?.new_budget}
                  onChange={(e) => {
                    const newVal = e.target.value.split(".").join("");
                    const isNumeric = /^[0-9]+$/.test(newVal);
                    if (isNumeric || e.target.value === "") {
                      UpdateCampaignDataList("new_budget", e.target.value);
                    }
                  }}
                  addonBefore={
                    <span className=" bg-white fw-bolder fs-5">
                      {selected_currency_sign}
                    </span>
                  }
                  size="large"
                />
              </div>{" "}
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">Status</label>
                <Select
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={campaignData?.state}
                  size="large"
                  onChange={(e) => {
                    UpdateCampaignDataList("state", e);
                  }}
                  options={[
                    { label: "Enabled", value: "ENABLED" },
                    { label: "Paused", value: "PAUSED" },
                  ]}
                />
              </div>
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">Start Date</label>
                <DatePicker
                  format={DateFormat}
                  style={{ width: "100%" }}
                  size="large"
                  className="ant_common_input"
                  value={dayjs(campaignData?.start_date)}
                  disabled={true}
                />
              </div>
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">End Date</label>
                <DatePicker
                  format={DateFormat}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(date, dateString) => {
                    UpdateCampaignDataList(
                      "end_date",
                      date ? moment(date?.$d)?.format("YYYY-MM-DD") : null
                    );
                  }}
                  style={{ width: "100%" }}
                  size="large"
                  className="ant_common_input"
                  value={
                    campaignData?.end_date
                      ? dayjs(campaignData?.end_date)
                      : null
                  }
                />
              </div>
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">
                  Bidding Strategy
                </label>
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={campaignData?.bidding_strategy}
                  options={[
                    {
                      label: "Dynamic bids-down only",
                      value: "LEGACY_FOR_SALES",
                    },
                    {
                      label: "Dynamic bids-up and down",
                      value: "AUTO_FOR_SALES",
                    },
                    { label: "Fixed bid", value: "MANUAL" },
                  ]}
                  onChange={(e) => {
                    UpdateCampaignDataList("bidding_strategy", e);
                  }}
                  size="large"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">Portfolio Id</label>
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={campaignData?.portfolio_id}
                  placeholder="Select Portfolio"
                  onChange={(e) => {
                    UpdateCampaignDataList("portfolio_id", e);
                  }}
                  options={portfolioList?.map((d) => {
                    return { label: d?.name, value: `${d?.portfolioId}` };
                  })}
                />
              </div>{" "}
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">
                  Top of search (First Page)
                </label>
                <Input
                  value={campaignData?.top_of_search_placement}
                  onChange={(e) => {
                    const newVal = e.target.value.split(".").join("");
                    const isNumeric = /^[0-9]+$/.test(newVal);
                    if (isNumeric || e.target.value === "") {
                      UpdateCampaignDataList(
                        "top_of_search_placement",
                        e.target.value
                      );
                    }
                  }}
                  addonAfter={
                    <span className=" bg-white fw-bolder fs-5">%</span>
                  }
                  size="large"
                />
              </div>
              <div className="col-4 mb-7">
                <label className="fw-bold fs-6 required">Product Pages</label>
                <Input
                  value={campaignData?.product_pages_placement}
                  onChange={(e) => {
                    const newVal = e.target.value.split(".").join("");
                    const isNumeric = /^[0-9]+$/.test(newVal);
                    if (isNumeric || e.target.value === "") {
                      UpdateCampaignDataList(
                        "product_pages_placement",
                        e.target.value
                      );
                    }
                  }}
                  addonAfter={
                    <span className=" bg-white fw-bolder fs-5">%</span>
                  }
                  size="large"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            disabled={
              sending ||
              !campaignData?.name ||
              !campaignData?.new_budget ||
              !campaignData?.start_date ||
              // !campaignData?.end_date ||
              !campaignData?.bidding_strategy ||
              !campaignData?.top_of_search_placement ||
              !campaignData?.product_pages_placement
            }
            onClick={() => {
              setSending(true);
              UpdateCampaign();
            }}
          >
            Submit
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
