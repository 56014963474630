import React, { useEffect, useState } from "react";
import SBAdFormat from "./lib/ad_format";
import SBAdgroup from "./lib/ad_group";
import SBProducts from "./lib/add_products";
import SBCreateAnAdd from "./lib/create_an_add";
import SBCreateCampaign from "./lib/create_campaign";
import SBManualTargeting from "./lib/manual_targeting";
import SBSteps from "./lib/steps";
import dayjs from "dayjs";
import { DefaultPerPage, DisplayNotification } from "../../../../../config";
import { dummyProductList } from "../../../../../config/select-dropdown-list";

const SponsoredBrand = () => {
  const [selectedStep, setSelectedStep] = useState("step1");
  const [product_list, set_product_list] = useState([]);
  const [selected_product_list, set_selected_product_list] = useState([]);
  const [negative_keyword_list, set_negative_keyword_list] = useState([]);
  const [selected_keyword_list, set_selected_keyword_list] = useState([]);
  const [selected_categroy_list, set_selected_categroy_list] = useState([]);
  const [
    selected_individual_product_list,
    set_selected_individual_product_list,
  ] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const [all_data, set_all_data] = useState({
    state: "PAUSED",
    campaign_ad_type: "SB",
    name: "",
    start_date: dayjs()?.format("YYYY-MM-DD"),
    end_date: dayjs().add(30, "d")?.format("YYYY-MM-DD"),
    daily_budget: "",
    run_indefinitely: false,
    ad_group: [],
    campaign_goal: "drive_page_visits",
    is_automate_bidding: false,
    bid_adjusted_by: "increase_by",
    bid_adjusted_value: "",
    product_store_video: "product",
    landing_page_option: "store_on_amazon",
    advertise_name: "",
    advertise_type: "MOBILE",
  });

  const [all_common_hide_show, set_all_common_hide_show] = useState({
    add_items: "search",
    target_type: "categories",
    categories_type: "suggested",
    individual_product_type: "suggested",
    negative_targeting: "negative_exact",
    manual_keyword_targeting: "suggested",
    manual_product_targeting: "category",
    manual_product_category: "suggested",
    manual_product_individual: "suggested",
  });

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search_key: "",
  });

  const [manual_sub_targeting_type, set_manual_sub_targeting_type] =
    useState("keyword_targeting");

  useEffect(() => {
    GetProductList(all_filters);
  }, []);

  const GetProductList = async (filters) => {
    if (dummyProductList?.status) {
      set_product_list(dummyProductList?.data?.records);
      setTotalPage(dummyProductList?.data?.pagination?.totalCount);
    } else {
      DisplayNotification("error", "Error", dummyProductList?.message);
    }
  };

  // return <div>Sponsored Brands</div>;
  // console.clear();
  console.log(all_data, "all_data");
  return (
    <>
      <div
        className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row align-items-start"
        id="kt_stepper_example_vertical"
      >
        <SBSteps
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          all_data={all_data}
        />
        <div className="flex-row-fluid">
          <div style={{ minHeight: 500 }}>
            <div className="mb-5 ">
              {selectedStep === "step1" && (
                <SBCreateCampaign
                  all_data={all_data}
                  set_all_data={set_all_data}
                />
              )}
              {selectedStep === "step2" && (
                <SBAdgroup all_data={all_data} set_all_data={set_all_data} />
              )}
              {selectedStep === "step3" && (
                <SBAdFormat all_data={all_data} set_all_data={set_all_data} />
              )}
              {selectedStep === "step4" &&
              all_data?.landing_page_option === "new_landing_page" ? (
                <SBProducts
                  product_list={product_list}
                  selected_product_list={selected_product_list}
                  set_selected_product_list={set_selected_product_list}
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                />
              ) : (
                ""
              )}
              {selectedStep === "step4" &&
              all_data?.landing_page_option !== "new_landing_page" ? (
                <SBManualTargeting
                  all_data={all_data}
                  negative_keyword_list={negative_keyword_list}
                  set_negative_keyword_list={set_negative_keyword_list}
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                  selected_product_list={selected_product_list}
                  manual_sub_targeting_type={manual_sub_targeting_type}
                  set_manual_sub_targeting_type={set_manual_sub_targeting_type}
                  selected_keyword_list={selected_keyword_list}
                  set_selected_keyword_list={set_selected_keyword_list}
                  selected_individual_product_list={
                    selected_individual_product_list
                  }
                  set_selected_individual_product_list={
                    set_selected_individual_product_list
                  }
                  selected_categroy_list={selected_categroy_list}
                  set_selected_categroy_list={set_selected_categroy_list}
                />
              ) : (
                ""
              )}
              {selectedStep === "step5" && (
                <SBCreateAnAdd
                  all_data={all_data}
                  set_all_data={set_all_data}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-stack">
            <div className="me-2">
              {selectedStep !== "step1" && (
                <button
                  type="button"
                  className="btn btn-dark fw-bold fs-7"
                  onClick={() => {
                    const newStep =
                      selectedStep === "step6"
                        ? "step5"
                        : selectedStep === "step5"
                        ? "step4"
                        : selectedStep === "step4"
                        ? "step3"
                        : selectedStep === "step3"
                        ? "step2"
                        : "step1";
                    setSelectedStep(newStep);
                  }}
                >
                  Back
                </button>
              )}
            </div>

            <div>
              <button
                type="button"
                className="btn btn-primary fs-7 fw-bold"
                data-kt-stepper-action="submit"
              >
                <span className="indicator-label">Launch Campaign</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </button>
              <button
                type="button"
                className="btn btn-primary fs-7 fw-bold"
                onClick={() => {
                  console.log("all_data >>>>", all_data);
                  if (
                    selectedStep === "step4" &&
                    all_data?.landing_page_option === "new_landing_page"
                  ) {
                  } else {
                    const newStep =
                      selectedStep === "step1"
                        ? "step2"
                        : selectedStep === "step2"
                        ? "step3"
                        : selectedStep === "step3"
                        ? "step4"
                        : selectedStep === "step4"
                        ? "step5"
                        : "step6";
                    setSelectedStep(newStep);
                  }
                }}
              >
                {selectedStep === "step4" &&
                all_data?.landing_page_option === "new_landing_page"
                  ? "Submit"
                  : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SponsoredBrand;
