// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/campaign-management",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-abstract-26 fs-1"></i>
      </span>
    ),
    label: GetLinks("/campaign-management", "Campaign Management"),
  },
];
