import React, { useState } from "react";
import { Select } from "antd";

const SBAdFormat = (props) => {
  const { all_data, set_all_data } = props;

  return (
    <>
      <div className="row gy-5 g-xl-5">
        <div className="col-xl-12">
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            <div className="card-header border-0 cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Ad Format</h3>
              </div>

              <div className="card-toolbar"></div>
            </div>

            <div className="card-body border-top border-top-dashed pb-2 pt-6">
              <div className="row gy-5 g-xl-5">
                <div className="col-md-4">
                  <input
                    type="radio"
                    className="btn-check"
                    id="product"
                    value="product"
                    checked={all_data?.product_store_video === "product"}
                    onChange={(event) => {
                      set_all_data({
                        ...all_data,
                        product_store_video: event.target.value,
                      });
                    }}
                  />
                  {
                    <label
                      htmlFor="product"
                      className={`btn btn-outline btn-outline-dashed ${
                        all_data?.product_store_video === "product" &&
                        "btn-active-light-primary"
                      } p-7 d-flex align-items-start mb-5 min-h-250px`}
                    >
                      <div>
                        <h6 className="mb-4">Product Collection</h6>
                        <img
                          src="assets/media/multi-product-amz.png"
                          className="w-75 h-100px border border-gray-300 border-2"
                          alt
                        />
                        <p className="mb-0 mt-4 fs-7">
                          Promote multiple products from a landing page of your
                          choice.
                        </p>
                      </div>
                    </label>
                  }
                </div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    className="btn-check"
                    id="store_spotlight"
                    value="store_spotlight"
                    checked={
                      all_data?.product_store_video === "store_spotlight"
                    }
                    onChange={(event) => {
                      set_all_data({
                        ...all_data,
                        product_store_video: event.target.value,
                      });
                    }}
                  />
                  {
                    <label
                      htmlFor="store_spotlight"
                      className={`btn btn-outline btn-outline-dashed ${
                        all_data?.product_store_video === "store_spotlight" &&
                        "btn-active-light-primary"
                      } p-7 d-flex align-items-start mb-5 min-h-250px`}
                    >
                      <div>
                        <h6 className="mb-4">Store Spotlight</h6>
                        <img
                          src="assets/media/product-amz.png"
                          className="w-75 h-100px border border-gray-300 border-2"
                          alt
                        />
                        <p className="mb-0 mt-4 fs-7">
                          Drive traffic to a Store, Including subpages
                        </p>
                      </div>
                    </label>
                  }
                </div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    className="btn-check"
                    id="video"
                    value="video"
                    checked={all_data?.product_store_video === "video"}
                    onChange={(event) => {
                      set_all_data({
                        ...all_data,
                        product_store_video: event.target.value,
                      });
                    }}
                  />
                  {
                    <label
                      htmlFor="video"
                      className={`btn btn-outline btn-outline-dashed ${
                        all_data?.product_store_video === "video" &&
                        "btn-active-light-primary"
                      } p-7 d-flex align-items-start mb-5 min-h-250px`}
                    >
                      <div>
                        <h6 className="mb-4">Video</h6>
                        <img
                          src="assets/media/video-a.png"
                          className="w-75 h-100px border border-gray-300 border-2"
                          alt
                        />
                        <p className="mb-0 mt-4 fs-7">
                          Promote your brand and a product using video. Drive
                          traffic to your store or the product details page.
                        </p>
                      </div>
                    </label>
                  }
                </div>
              </div>
              <div className="row gy-5 g-xl-5">
                <div className="col-md-12">
                  <div className="row g-5 align-items-center">
                    <div className="col-md-12">
                      <h5 className="mb-0">Landing Page</h5>
                    </div>
                    {all_data?.product_store_video === "store_spotlight" ? (
                      <div className="col-md-12">
                        {" "}
                        <div style={{ width: "30%" }} className="ms-2">
                          <label className="fw-bold fs-6">Choose a store</label>
                          <Select style={{ width: "100%" }} size="large" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-md-12">
                          <label className="d-flex flex-stack mb-0 cursor-pointer">
                            <span className="d-flex align-items-center me-2">
                              <span className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="landingoptions"
                                  defaultValue="store_on_amazon"
                                  // defaultChecked
                                  checked={
                                    all_data?.landing_page_option ===
                                    "store_on_amazon"
                                  }
                                  onChange={(event) => {
                                    set_all_data({
                                      ...all_data,
                                      landing_page_option: event.target.value,
                                    });
                                  }}
                                />
                              </span>
                              <span className="d-flex flex-column ms-5">
                                <span className="fw-bold fs-6">
                                  Store on Amazon (Included subpages)
                                </span>
                              </span>
                            </span>
                          </label>
                          <div className="d-flex mt-5">
                            <div style={{ width: "30%" }} className="ms-10">
                              <label className="fw-bold fs-6">
                                Choose a store
                              </label>
                              <Select style={{ width: "100%" }} size="large" />
                            </div>
                            <div style={{ width: "30%" }} className="ms-5">
                              <label className="fw-bold fs-6">
                                Choose a page
                              </label>
                              <Select style={{ width: "100%" }} size="large" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label className="d-flex flex-stack mb-0 cursor-pointer">
                            <span className="d-flex align-items-center me-2">
                              <span className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="landingoptions"
                                  defaultValue="new_landing_page"
                                  checked={
                                    all_data?.landing_page_option ===
                                    "new_landing_page"
                                  }
                                  onChange={(event) => {
                                    set_all_data({
                                      ...all_data,
                                      landing_page_option: event.target.value,
                                    });
                                  }}
                                />
                              </span>
                              <span className="d-flex flex-column ms-5">
                                <span className="fw-bold fs-6">
                                  {all_data?.product_store_video === "product"
                                    ? "New landing page"
                                    : "Product detail page"}
                                </span>
                                {all_data?.product_store_video ===
                                  "product" && (
                                  <span>
                                    Pick products to advertise and we’ll create
                                    a landing page for you.
                                  </span>
                                )}
                              </span>
                            </span>
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SBAdFormat;
