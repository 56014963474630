import axios from "axios";
import { ENDPOINT } from "../config";
import { message } from "antd";

// admin = 1
// user = 2
// seller = 3
// sub user = 4  (sub user)

// const getToken = () => localStorage.getItem("token");

// Common function for making API calls
export const TempMakeApiCall = async (
  url,
  method = "get",
  data = null,
  withBearer = false,
  isFormData = false
) => {
  try {
    const headers = {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    };

    if (withBearer) {
      // Assuming you have a way to retrieve the authentication token

      headers["Authorization"] = `Bearer ${localStorage.getItem(
        "temp_user_token"
      )}`;
    }
    let url_ = ENDPOINT + url;
    const response = await axios({
      method,
      url: url_,
      data: data || null,
      // data: data ? JSON.stringify(data) : null,
      headers,
    });

    return response.data;
  } catch (error) {
    // Handle errors here (e.g., log them or throw a custom error)
    const newData = JSON.stringify(error);
    const finalData = JSON.parse(newData);
    if (finalData?.status == 401) {
    } else {
      const errorData = {
        status: false,
        message: "Something went wrong",
      };
      return errorData;
    }
    // throw { message: error?.message };
  }
};
