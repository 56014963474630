// import Icons from "../../../../components/icons";

import { GetLinks } from "../../../../core";

export default [
  {
    key: "/application-logs",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-chart-simple-2 fs-1"></i>
      </span>
    ),
    label: "Application Logs",
    children: [
      {
        key: "/application-logs/central-log",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/application-logs/central-log", "Central Log"),
      },
      {
        key: "/application-logs/system-error-log",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/application-logs/system-error-log",
          "System Error Log"
        ),
      },
    ],
  },
];
