import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-tabs-tab {
    background-color: #fff !important;
    border-radius: 0.475rem !important;
    color: var(--bs-nav-link-color);
    font-size: 0.95rem !important;
  }
  .ant-tabs-tab-active {
    background-color: var(--bs-primary) !important;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
`;
