import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { MakeApiCall } from "../../../apis/make-api-call";
import { DisplayNotification } from "../../../config";
import { Input } from "antd";

function Login() {
  const contextValue = useContext(GlobalContext);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
  });

  const submit = async () => {
    setLoading(true);
    const response = await MakeApiCall("login", "post", { ...userInfo });
    if (response?.status) {
      SetUserInformation(contextValue, response?.data);
      setLoading(false);
      setTimeout(() => {
        if (response?.data?.user_data?.user_type == 2) {
          GetSubUserList();
        } else {
          setRedirect(true);
        }
      }, 800);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetSubUserList = async () => {
    //  const data = {
    //    user_type: 2,
    //    parent: 2,
    //  };
    localStorage.setItem("token_type", "main_token");
    const response = await MakeApiCall(
      `user/get-all-sub-user`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      if (response?.data?.length > 0) {
        localStorage.setItem("token_type", "");
        localStorage.setItem("sub_user_list", JSON.stringify(response?.data));
        contextValue?.updateCommonUserListVal({
          user_list: JSON.stringify(response?.data),
        });
      } else {
        localStorage.removeItem("sub_user_list");
      }
      setRedirect(true);
    } else {
      localStorage.setItem("token_type", "");
      DisplayNotification("error", "Error", response?.message);
    }
  };

  if (localStorage.getItem("token") || redirect) {
    return (
      <Navigate
        to={
          localStorage.getItem("user_type") == 1 ? `/manage-user` : "/dashboard"
        }
      />
    );
  }
  const SetUserInformation = (contextValue, response) => {
    contextValue?.updateCommonGlobalVal({
      isAdmin: 0,
      user_data: JSON.stringify(response?.user_data),
      user_type: response?.user_data?.user_type,
      primary_marketplace: response?.user_data?.default_marketplace || null,
      selected_sub_user: response?.user_data?.sub_user?.name || null,
    });
    localStorage.setItem("isAdmin", 0);
    localStorage.setItem(
      "primary_marketplace",
      response?.user_data?.default_marketplace || null
    );
    localStorage.setItem("user_data", JSON.stringify(response?.user_data));
    localStorage.setItem("token", response?.auth_token);
    localStorage.setItem(
      "sub_user_token",
      response?.user_data?.sub_user?.auth_token
    );
    localStorage.setItem("user_type", response?.user_data?.user_type);
    localStorage.setItem(
      "selected_sub_user",
      response?.user_data?.sub_user?.name || null
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
        style={{
          height: "100%",
          backgroundImage: `url(/assets/media/bg6.jpg)`,
        }}
      >
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40 mx-auto position-relative">
          <div className="b1" />
          <div className="b2" />

          <div
            className="d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 "
            style={{
              backgroundColor: "#0000002e",
              backdropFilter: "blur(18px)",
              border: "2px solid #ffffff26",
            }}
          >
            <div className=" text-center">
              <img src="/assets/media/logo.png" className="h-40px" alt />
            </div>
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-10">
              <div className="form w-100">
                <div className="text-center mb-18">
                  <h1 className="tracking-in-expand-fwd  text-gray-100 fw-bold fs-2x mb-3">
                    Welcome in{" "}
                    <span className="text-primary">eStore Factory</span>
                  </h1>

                  <div className="text-gray-200 fw-semibold fs-6">
                    Please enter your account details.
                  </div>
                </div>

                <div className="fv-row mb-8">
                  <Input
                    type="text"
                    placeholder="Email"
                    name="email"
                    autoComplete="off"
                    className="form-control  h-60px "
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, username: e.target.value });
                    }}
                  />
                </div>

                <div className="fv-row mb-3 position-relative">
                  <Input
                    type="password"
                    id="password"
                    placeholder="Password"
                    name="password"
                    autoComplete="off"
                    className="form-control  h-60px "
                    onChange={(e) => {
                      setUserInfo({ ...userInfo, password: e.target.value });
                    }}
                  />
                </div>

                <div className="d-flex flex-center mt-5 flex-wrap gap-3 fs-base fw-semibold mb-8">
                  <a href className="text-light">
                    Forgot Password ?
                  </a>
                </div>

                <div className="d-grid mb-10">
                  <button
                    disabled={!userInfo?.username || !userInfo?.password}
                    className="btn btn-primary h-50px"
                    onClick={() => {
                      submit();
                    }}
                  >
                    {loading ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    ) : (
                      <span className="indicator-label">Sign In</span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex flex-stack px-lg-10 justify-content-center ">
              <div className="text-gray-200 text-center fw-semibold fs-6">
                Not a Member yet?
                <span className="text-light">Sign up</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
