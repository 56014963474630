import { LoadingOutlined } from "@ant-design/icons";
import { Input, message, Popover, Radio, Skeleton, Switch, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../../apis/make-api-call";
import { DisplayNotification, downloadFile } from "../../../../../../config";
import NoData from "../../../../../../component/no-data";
const { Dragger } = Upload;
const SPStep4 = (props) => {
  const {
    all_common_hide_show,
    set_all_common_hide_show,
    selected_negative_keyword,
    set_selected_negative_keyword,
    campaign_data_list,
    manual_sub_targeting_type,
    selected_negative_brand_product,
    set_selected_negative_brand_product,
    selected_marketplace,
  } = props;
  const [keyword, setKeyword] = useState("");
  const [product_loading, set_product_loading] = useState(false);
  const [product_list, set_product_list] = useState([]);
  const [manual_negative_product_search, set_manual_negative_product_search] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [negative_brand_list, set_negative_brand_list] = useState([]);

  const [isAutoUploading, setAutoIsUploading] = useState(false);
  const [inValidAsin, setInValidAsin] = useState([]);
  const [auto_negative_product_search, set_auto_negative_product_search] =
    useState([]);
  const [auto_enter_list_val, set_auto_enter_list_val] = useState([]);
  const [auto_product_list, set_auto_product_list] = useState([]);
  const [auto_product_loading, set_auto_product_loading] = useState(false);
  const [selected_auto_product_list, set_selected_auto_product_list] = useState(
    []
  );

  useEffect(() => {
    GetNegativeBrandList();
  }, []);

  const GetNegativeBrandList = async () => {
    const response = await MakeApiCall(
      `advertising/get-negative-targeting-brand-suggestions`,
      `POST`,
      {
        marketplace_id: selected_marketplace,
      },
      true
    );
    if (response?.status) {
      set_negative_brand_list(response?.data);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetNegativeBrandSearchList = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-negative-targeting-brand-search`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      set_negative_brand_list(response?.data);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetManualNegativeProduct = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      set_product_loading(false);
      set_product_list(response?.data?.records);
    } else {
      set_product_loading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAutoNegativeProduct = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      set_auto_product_loading(false);
      set_auto_product_list(response?.data?.records);
    } else {
      set_auto_product_loading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetAutoNegativeEnterListProduct = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setLoading(false);

      const data = response?.data?.records?.map((d) => {
        const newObj = {
          id: d?.id,
          expression_type: "ASIN_SAME_AS",
          state: "ENABLED",
          expression_value: d?.asin,
          name: d?.product_name,
          image_url: d?.image_url,
          type: "products",
        };
        set_selected_negative_brand_product((prev) => {
          const existingASIN = prev?.find(
            (es) => es?.expression_value === d?.asin
          );
          if (existingASIN) {
            return prev.map((sk) =>
              sk?.expression_value === d?.asin
                ? {
                    ...sk,
                    ...newObj,
                  }
                : sk
            );
          } else {
            return [...prev, newObj];
          }
        });
      });
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const customUpload = async (props) => {
    const { file, onSuccess, onError } = props;
    return;
    try {
      setAutoIsUploading(true);
      const formData = new FormData();
      formData.append("product_template_file", file);
      formData.append("marketplace_id", selected_marketplace);
      formData.append(
        "product_asins",
        selected_auto_product_list?.map((d) => {
          return d?.asin1;
        })
      );
      const response = await MakeApiCall(
        `advertising/check-product-by-file`,
        `POST`,
        formData,
        true,
        true
      );
      if (response?.status) {
        setAutoIsUploading(false);
        message.destroy();
        DisplayNotification(
          "success",
          "Success",
          "Asin list uploaded successfully"
        );
        const newData = response?.data?.product_list?.map((d) => {
          return { ...d, state: "ENABLED" };
        });
        set_selected_auto_product_list(newData || []);
      } else {
        DisplayNotification("error", "Error", response?.message);
      }
    } catch (error) {
      message.destroy();
      DisplayNotification("error", "Error", error);
    }
  };

  const beforeUpload = (file) => {
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcel) {
      message.error(`${file.name} is not an excel file`);
    }

    return isExcel || Upload.LIST_IGNORE; // Ignore the file if it's not Excel
  };

  return (
    <>
      {/* ---------------------------------------------------------------------------- Auto Manual Type keyword Targeting */}
      {campaign_data_list.targeting_type === "AUTO" ||
      (campaign_data_list.targeting_type === "MANUAL" &&
        manual_sub_targeting_type === "keyword_targeting") ? (
        <div
          className="flex-column current mb-10"
          data-kt-stepper-element="content"
          style={{ minHeight: 400 }}
        >
          <div className="row gy-5 g-xl-5">
            <div className="col-xxl-6">
              <div className="card min-h-450px">
                <div className="card-header card-header-stretch">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      Negative keyword targeting
                      <small className="fw-semibold ms-2">(Optional)</small>
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className="row align-items-center mb-7">
                    <div className="col-md-3">
                      <span className="fw-bolder fs-6">Match Type</span>
                    </div>
                    <div className="col-md-9">
                      <div className=" d-flex gap-5 mt-0">
                        <Radio.Group
                          name="radiogroup"
                          onChange={(e) => {
                            set_all_common_hide_show({
                              ...all_common_hide_show,
                              negative_targeting: e.target.value,
                            });
                          }}
                          value={all_common_hide_show?.negative_targeting}
                        >
                          <Radio value={"NEGATIVE_EXACT"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Negative Exact
                              </span>
                            </span>
                          </Radio>
                          <Radio value={"NEGATIVE_PHRASE"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Negative Phrase
                              </span>
                            </span>
                          </Radio>
                          {/* <Radio value={"NEGATIVE_BROAD"}>
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">Negative Broad</span>
                          </span>
                        </Radio> */}
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="mt-0">
                    <Input.TextArea
                      cols={30}
                      rows={5}
                      placeholder="Enter keywords "
                      value={keyword}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                    {/* <div id className="mt-2 text-success">
                    1 / 1 keywords were added successfully.
                  </div> */}
                  </div>
                  <div className="mt-3 text-start">
                    <button
                      disabled={!keyword}
                      className="btn btn-primary fs-7"
                      onClick={() => {
                        if (keyword) {
                          const check_keyword =
                            selected_negative_keyword?.filter(
                              (item) =>
                                item?.keyword === keyword?.trim() &&
                                item?.match_type ===
                                  all_common_hide_show?.negative_targeting
                            );
                          if (check_keyword?.length > 0) {
                            message.destroy();
                            message.info("Keyword Exists");
                            setKeyword("");
                            return false;
                          }
                          set_selected_negative_keyword([
                            ...selected_negative_keyword,
                            {
                              match_type:
                                all_common_hide_show?.negative_targeting,
                              state: "ENABLED",
                              keyword: keyword,
                            },
                          ]);
                          setKeyword("");
                        }
                      }}
                    >
                      Add Keyword
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-6">
              <div className="card min-h-450px">
                <div className="card-header ">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      {selected_negative_keyword?.length} Keywords
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <button
                      disabled={selected_negative_keyword?.length == 0}
                      className="btn fs-7 btn-light-primary fw-bold btn-sm"
                      onClick={() => {
                        set_selected_negative_keyword([]);
                      }}
                    >
                      Remove All
                    </button>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{
                    height: "380px",
                    overflow: "auto",
                  }}
                >
                  <div className="mt-0">
                    <div className="rounded bg-light p-3 mb-4">
                      <div className="row">
                        <div className="col-6 fw-bold">Keyword</div>
                        <div className="col-3 fw-bold">Match Type</div>
                        <div className="col-3 fw-bold">Action</div>
                      </div>
                    </div>
                    {selected_negative_keyword?.length == 0 ? (
                      <div className="text-center mt-2">
                        <label className="fw-bold fs-4">No Data</label>
                      </div>
                    ) : (
                      selected_negative_keyword?.map((d, i) => {
                        return (
                          <div className="rounded bg-light p-3 mb-4">
                            <div className="row d-flex align-items-center">
                              <div className="col-6">{d?.keyword}</div>
                              <div className="col-3">
                                <b className="text-gray-800 fs-7 text-capitalize">
                                  {d?.match_type
                                    ?.toLowerCase()
                                    .replaceAll("_", " ")}
                                </b>
                              </div>
                              <div className="col-3">
                                <button
                                  className="btn btn-light bg-white btn-sm btn-icon"
                                  onClick={() => {
                                    const updatedList =
                                      selected_negative_keyword.filter(
                                        (item, ki) => ki !== i
                                      );
                                    set_selected_negative_keyword(updatedList);
                                  }}
                                >
                                  <i className="ki-outline ki-cross-square text-primary fs-2" />
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* ---------------------------------------------------------------------------- Manual Type Product Targeting */}
      {campaign_data_list.targeting_type === "MANUAL" &&
      manual_sub_targeting_type === "product_targeting" ? (
        <div
          className="flex-column current"
          data-kt-stepper-element="content"
          style={{ minHeight: 400 }}
        >
          <div className="row gy-5 g-xl-5">
            <div className="col-xxl-6">
              <div
                className="card"
                style={{
                  minHeight: "610px",
                }}
              >
                <div className="card-header card-header-stretch">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      Negative Product targeting
                      <small className="fw-semibold ms-2">(Optional)</small>
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body p-6">
                  <div className="row px-0 mx-0 mb-5">
                    <div className="d-flex justify-content-between">
                      <div>
                        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                          <li
                            className="nav-item cursor-pointer "
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                negative_product_targeting: "exclude_brand",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.negative_product_targeting ===
                                "exclude_brand"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Exclude brands
                            </span>
                          </li>
                          <li
                            className="nav-item cursor-pointer "
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                negative_product_targeting: "exclude_products",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.negative_product_targeting ===
                                "exclude_products"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Exclude products
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-end">
                      {/* {all_common_hide_show?.negative_product_targeting ===
                        "exclude_products" && (
                        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                          <li
                            className="nav-item cursor-pointer "
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                negative_exclude_product: "search",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.negative_exclude_product ===
                                "search"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Search
                            </span>
                          </li>
                          <li
                            className="nav-item cursor-pointer "
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                negative_exclude_product: "enter_list",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.negative_exclude_product ===
                                "enter_list"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Enter List
                            </span>
                          </li>
                          <li
                            className="nav-item cursor-pointer "
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                negative_exclude_product: "upload",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.negative_exclude_product ===
                                "upload"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Upload
                            </span>
                          </li>
                        </ul>
                      )} */}
                    </div>
                  </div>
                  {all_common_hide_show?.negative_product_targeting ===
                    "exclude_brand" && (
                    <>
                      <div className="row align-items-center mb-7">
                        <div className="position-relative mb-2">
                          <Input
                            prefix={
                              <i className="ki-outline ki-magnifier fs-2" />
                            }
                            size="large"
                            placeholder="Search by brand name"
                            onPressEnter={(e) => {
                              GetNegativeBrandSearchList({
                                marketplace_id: selected_marketplace,
                                keyword: e.target.value,
                              });
                            }}
                            // onPressEnter={(e) => {
                            //   const apiObj = {
                            //     ...all_filters,
                            //     page: 1,
                            //     "per-page": 1,
                            //     search: e.target.value,
                            //   };
                            //   set_all_filters(apiObj);
                            //   set_product_loading(true);
                            //   GetProductList(apiObj);
                            // }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                          <div>
                            <label className="fw-bold fs-5">Your Brands</label>
                          </div>
                          <div>
                            {/* <button className="btn btn-light-primary btn-sm">
                              Exclude all
                            </button> */}
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-dashed border-gray-300 my-2" />
                      <div className="row">
                        {negative_brand_list?.length > 0 ? (
                          negative_brand_list?.map((d) => {
                            const isBrandAdded =
                              selected_negative_brand_product?.filter(
                                (f) => f?.id === d?.id
                              );
                            console.log(isBrandAdded, "-isBrandAdded");
                            return (
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <label className="fw-bold fs-5">
                                    {d?.name}
                                  </label>
                                  <div>
                                    {isBrandAdded?.length > 0 ? (
                                      <label className="text-muted">
                                        Excluded
                                      </label>
                                    ) : (
                                      <label
                                        className="fw-bold cursor-pointer text-info"
                                        onClick={() => {
                                          set_selected_negative_brand_product([
                                            ...selected_negative_brand_product,
                                            {
                                              id: d?.id,
                                              expression_type:
                                                "ASIN_BRAND_SAME_AS",
                                              state: "ENABLED",
                                              expression_value: d?.id,
                                              name: d?.name,
                                            },
                                          ]);
                                        }}
                                      >
                                        Exclude
                                      </label>
                                    )}
                                  </div>
                                </div>
                                <div className="separator separator-dashed border-gray-300 my-3"></div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="col-12 text-center">
                            <label className="fw-bold fs-5">No Data</label>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {all_common_hide_show?.negative_product_targeting ===
                    "exclude_products" && (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div className="tab-content" id="myTabContent">
                            <div
                              className={`tab-pane fade  ${
                                all_common_hide_show?.negative_exclude_product ===
                                "search"
                                  ? "show active"
                                  : ""
                              }`}
                            >
                              <div className="mt-0">
                                <div className="position-relative mb-2">
                                  <Input
                                    prefix={
                                      <i className="ki-outline ki-magnifier fs-2" />
                                    }
                                    size="large"
                                    placeholder="Search by product name or ASIN"
                                    value={manual_negative_product_search}
                                    onChange={(e) => {
                                      set_manual_negative_product_search(
                                        e.target.value
                                      );
                                    }}
                                    onPressEnter={() => {
                                      const data = {
                                        marketplace_id: selected_marketplace,
                                        product_asins: [],
                                        asins: [
                                          manual_negative_product_search?.trim(),
                                        ],
                                      };
                                      set_product_loading(true);
                                      GetManualNegativeProduct(data);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="text-end"></div>
                              <div
                                className="mt-5"
                                style={{ height: "318px", overflow: "scroll" }}
                              >
                                {product_loading ? (
                                  <>
                                    <Skeleton.Button
                                      active
                                      block
                                      className="my-1"
                                    />
                                    <Skeleton.Button
                                      active
                                      block
                                      className="my-1"
                                    />
                                    <Skeleton.Button
                                      active
                                      block
                                      className="my-1"
                                    />
                                    <Skeleton.Button
                                      active
                                      block
                                      className="my-1"
                                    />
                                    <Skeleton.Button
                                      active
                                      block
                                      className="my-1"
                                    />
                                  </>
                                ) : product_list?.length > 0 ? (
                                  <div>
                                    {product_list?.map((d, i) => {
                                      const isProductAdded =
                                        selected_negative_brand_product?.filter(
                                          (f) => f?.expression_value === d?.asin
                                        );
                                      return (
                                        <div className="rounded bg-light p-2 mb-3">
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                              <img
                                                src={d?.image_url}
                                                className
                                                alt
                                              />
                                            </div>
                                            <div className="d-flex flex-column me-2 fs-7">
                                              <div className="d-flex">
                                                <Popover
                                                  content={
                                                    <div
                                                      style={{
                                                        height: "max-content",
                                                        maxWidth: "400px",
                                                      }}
                                                    >
                                                      {d?.product_name}
                                                    </div>
                                                  }
                                                  placement="bottom"
                                                >
                                                  <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                                    {d?.product_name}
                                                  </span>
                                                </Popover>
                                              </div>
                                              <span className="text-gray-700 fs-8">
                                                ASIN:
                                                <b className="text-primary">
                                                  {d?.asin}
                                                </b>
                                              </span>
                                            </div>
                                            <div>
                                              {isProductAdded?.length == 0 && (
                                                <span
                                                  className="btn btn-light bg-white btn-sm btn-icon"
                                                  onClick={() => {
                                                    set_selected_negative_brand_product(
                                                      [
                                                        ...selected_negative_brand_product,
                                                        {
                                                          id: d?.id,
                                                          expression_type:
                                                            "ASIN_SAME_AS",
                                                          state: "ENABLED",
                                                          expression_value:
                                                            d?.asin,
                                                          name: d?.product_name,
                                                          image_url:
                                                            d?.image_url,
                                                          type: "products",
                                                        },
                                                      ]
                                                    );
                                                  }}
                                                >
                                                  <i className="ki-outline ki-plus fs-3 text-danger" />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                    {/* <Pagination
                                    loading={
                                      product_loading ||
                                      product_list?.length === 0
                                    }
                                    pageSize={all_filters?.["per-page"]}
                                    page={all_filters?.page}
                                    totalPage={totalPage}
                                    onPerPage={onPerPage}
                                    onPageNo={onPageNo}
                                  /> */}
                                  </div>
                                ) : (
                                  "No Data"
                                )}
                              </div>
                            </div>
                            {/* <div
                              className={`tab-pane fade  ${
                                all_common_hide_show?.negative_exclude_product ===
                                "enter_list"
                                  ? "show active"
                                  : ""
                              }`}
                            >
                              <div className>
                                <Input.TextArea
                                  onPressEnter={(e) => {
                                    // const finalAsin = e?.target.value?.split(",");
                                    // const data = {
                                    //   marketplace_id: selected_marketplace,
                                    //   product_asins: selected_product_list?.map(
                                    //     (d) => {
                                    //       return d?.asin1;
                                    //     }
                                    //   ),
                                    //   asins: finalAsin.map((str) => str.trim()),
                                    // };
                                    // setLoading(true);
                                    // GetEnterListProducts(data);
                                  }}
                                  onChange={(e) => {
                                    // set_enter_list_val(e.target.value);
                                  }}
                                  // value={enter_list_val}
                                  cols={30}
                                  rows={10}
                                  placeholder="Enter ASINs seprated by comma, space or new line."
                                />
                                <div className="mt-3 text-end">
                                  <button
                                    className="btn btn-dark fs-7"
                                    disabled={loading}
                                    // onClick={() => {
                                    //   const finalAsin =
                                    //     enter_list_val?.split(",");
                                    //   const data = {
                                    //     marketplace_id: selected_marketplace,
                                    //     product_asins: selected_product_list?.map(
                                    //       (d) => {
                                    //         return d?.asin1;
                                    //       }
                                    //     ),
                                    //     asins: finalAsin.map((str) => str.trim()),
                                    //   };
                                    //   setLoading(true);
                                    //   GetEnterListProducts(data);
                                    // }}
                                  >
                                    {loading && (
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    )}
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`tab-pane fade  ${
                                all_common_hide_show?.negative_exclude_product ===
                                "upload"
                                  ? "show active"
                                  : ""
                              }`}
                            >
                              <p className="mb-3 mt-2 text-muted">
                                Use the template to make sure your information
                                uploads accurately.
                              </p>
                              <div className="mb-5">
                                <span className="fw-bold text-primary">
                                  <i className="bi bi-download text-primary me-3" />
                                  Download the csv template
                                </span>
                              </div>

                              <div className="fv-row">
                                <div
                                  className="dropzone"
                                  id="kt_dropzonejs_example_1"
                                >
                                  <div className="dz-message needsclick">
                                    <i className="ki-duotone ki-file-up fs-3x text-primary">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>

                                    <div className="ms-4">
                                      <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                        Drop files here or click to upload.
                                      </h3>
                                      <span className="fs-7 fw-semibold text-gray-600">
                                        Accepted formats: CSV, TSV, XLSX
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xxl-6">
              <div className="card">
                <div className="card-header ">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      {/* {selected_negative_keyword?.length}  */}
                      {/* Added */}
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <button
                      disabled={selected_negative_brand_product?.length == 0}
                      className="btn fs-7 btn-light-primary fw-bold btn-sm"
                      onClick={() => {
                        set_selected_negative_brand_product([]);
                      }}
                    >
                      Remove All
                    </button>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{
                    minHeight: "540px",
                    overflow: "auto",
                  }}
                >
                  <div className="row">
                    {selected_negative_brand_product?.length > 0 ? (
                      selected_negative_brand_product?.map((d, i) => {
                        return d?.type === "products" ? (
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                <img src={d?.image_url} className alt />
                              </div>
                              <div className="d-flex flex-column me-2 fs-7">
                                <div className="d-flex">
                                  <Popover
                                    content={
                                      <div
                                        style={{
                                          height: "max-content",
                                          maxWidth: "400px",
                                        }}
                                      >
                                        {d?.name}
                                      </div>
                                    }
                                    placement="bottom"
                                  >
                                    <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                      {d?.name}
                                    </span>
                                  </Popover>
                                </div>
                                <span className="text-gray-700 fs-8">
                                  ASIN:
                                  <b className="text-primary">
                                    {d?.expression_value}
                                  </b>
                                </span>
                              </div>
                              <div
                                className="fw-bold"
                                onClick={() => {
                                  const updatedList =
                                    selected_negative_brand_product.filter(
                                      (item, index) => index !== i
                                    );
                                  set_selected_negative_brand_product(
                                    updatedList
                                  );
                                }}
                              >
                                <i className="ki-outline ki-cross-square text-primary fs-2" />
                              </div>
                            </div>{" "}
                            <div className="separator separator-dashed border-gray-300 my-3"></div>
                          </div>
                        ) : (
                          <div className="col-12">
                            <div className="d-flex justify-content-between">
                              <div>
                                <div>
                                  <label>{d?.name}</label>
                                </div>
                              </div>
                              <div
                                className="fw-bold"
                                onClick={() => {
                                  const updatedList =
                                    selected_negative_brand_product.filter(
                                      (item, index) => index !== i
                                    );
                                  set_selected_negative_brand_product(
                                    updatedList
                                  );
                                }}
                              >
                                <i className="ki-outline ki-cross-square text-primary fs-2" />
                              </div>
                            </div>
                            <div className="separator separator-dashed border-gray-300 my-3"></div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12"></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* ---------------------------------------------------------------------------- Auto Type Product Targeting */}
      {campaign_data_list.targeting_type === "AUTO" && (
        <div
          className="flex-column current"
          data-kt-stepper-element="content"
          style={{ minHeight: 500 }}
        >
          <div className="row gy-5 g-xl-5">
            <div className="col-md-12 d-flex">
              <div className="position-relative w-350px"></div>
            </div>

            <div className="col-xxl-6">
              <div className="card min-h-450px">
                <div className="card-header card-header-stretch">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      Negative Product targeting
                      <small className="fw-semibold ms-2">(Optional)</small>
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className="mb-5 d-flex justify-content-end">
                    <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                      <li
                        className="nav-item cursor-pointer "
                        onClick={() => {
                          set_all_common_hide_show({
                            ...all_common_hide_show,
                            auto_negative_product: "search",
                          });
                        }}
                      >
                        <span
                          className={`nav-link fw-bold text-gray-800 ${
                            all_common_hide_show?.auto_negative_product ===
                            "search"
                              ? "active"
                              : ""
                          }`}
                        >
                          Search
                        </span>
                      </li>
                      <li
                        className="nav-item cursor-pointer "
                        onClick={() => {
                          set_all_common_hide_show({
                            ...all_common_hide_show,
                            auto_negative_product: "enter_list",
                          });
                        }}
                      >
                        <span
                          className={`nav-link fw-bold text-gray-800 ${
                            all_common_hide_show?.auto_negative_product ===
                            "enter_list"
                              ? "active"
                              : ""
                          }`}
                        >
                          Enter List
                        </span>
                      </li>
                      <li
                        className="nav-item cursor-pointer "
                        onClick={() => {
                          set_all_common_hide_show({
                            ...all_common_hide_show,
                            auto_negative_product: "upload",
                          });
                        }}
                      >
                        <span
                          className={`nav-link fw-bold text-gray-800 ${
                            all_common_hide_show?.auto_negative_product ===
                            "upload"
                              ? "active"
                              : ""
                          }`}
                        >
                          Upload
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade  ${
                        all_common_hide_show?.auto_negative_product === "search"
                          ? "show active"
                          : ""
                      }`}
                    >
                      <div className="mt-0">
                        <div className="position-relative mb-2">
                          <Input
                            prefix={
                              <i className="ki-outline ki-magnifier fs-2" />
                            }
                            size="large"
                            placeholder="Search by product name or ASIN"
                            value={auto_negative_product_search}
                            onChange={(e) => {
                              set_auto_negative_product_search(e.target.value);
                            }}
                            onPressEnter={(e) => {
                              const data = {
                                marketplace_id: selected_marketplace,
                                product_asins: [],
                                asins: [auto_negative_product_search?.trim()],
                              };
                              set_auto_product_loading(true);
                              GetAutoNegativeProduct(data);
                            }}
                          />
                        </div>

                        {/* <p className="mb-0 mt-3 fs-7 text-primary">
                        Search for products you want to exclude.
                      </p> */}
                        {/* <Checkbox
                        onChange={(e) => {
                          product_list?.map((d) => {
                            const checkboxId = d?.id;
                            setLocalChecked((prev) => {
                              if (e?.target?.checked) {
                                return [...prev, { ...d }];
                              } else {
                                return prev.filter(
                                  (prod) => prod?.id !== checkboxId
                                );
                              }
                            });
                          });
                        }}
                      /> */}
                      </div>
                      <div className="text-end">
                        {/* <button
                          className="btn btn-light-primary btn-sm ms-2"
                          disabled={
                            auto_product_list?.length ==
                            selected_auto_product_list?.length
                          }
                          onClick={() => {
                            // set_selected_product_list(
                            //   product_list?.map((d) => {
                            //     return { ...d, state: "ENABLED" };
                            //   })
                            // );
                          }}
                        >
                          Add all
                        </button> */}
                      </div>
                      <div
                        className="mt-5"
                        style={{ height: "318px", overflow: "scroll" }}
                      >
                        {/* {console.log(
                        localChecked,
                        "-product_listproduct_listproduct_listproduct_list"
                      )} */}
                        {auto_product_loading ? (
                          <>
                            <Skeleton.Button active block className="my-1" />
                            <Skeleton.Button active block className="my-1" />
                            <Skeleton.Button active block className="my-1" />
                            <Skeleton.Button active block className="my-1" />
                            <Skeleton.Button active block className="my-1" />
                          </>
                        ) : auto_product_list?.length > 0 ? (
                          <div>
                            {auto_product_list?.map((d, i) => {
                              const isProductAdded =
                                selected_negative_brand_product?.filter(
                                  (f) => f?.expression_value === d?.asin
                                );
                              return (
                                <div className="rounded bg-light p-2 mb-3">
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                      <img src={d?.image_url} className alt />
                                    </div>
                                    <div className="d-flex flex-column me-2 fs-7">
                                      <div className="d-flex">
                                        <Popover
                                          content={
                                            <div
                                              style={{
                                                height: "max-content",
                                                maxWidth: "400px",
                                              }}
                                            >
                                              {d?.product_name}
                                            </div>
                                          }
                                          placement="bottom"
                                        >
                                          <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                            {d?.product_name}
                                          </span>
                                        </Popover>
                                      </div>
                                      <span className="text-gray-700 fs-8">
                                        ASIN:
                                        <b className="text-primary">
                                          {d?.asin}
                                        </b>
                                      </span>
                                    </div>
                                    <div>
                                      {isProductAdded?.length == 0 && (
                                        <span
                                          className="btn btn-light bg-white btn-sm btn-icon"
                                          onClick={() => {
                                            set_selected_negative_brand_product(
                                              [
                                                ...selected_negative_brand_product,
                                                {
                                                  id: d?.id,
                                                  expression_type:
                                                    "ASIN_SAME_AS",
                                                  state: "ENABLED",
                                                  expression_value: d?.asin,
                                                  name: d?.product_name,
                                                  image_url: d?.image_url,
                                                  type: "products",
                                                },
                                              ]
                                            );
                                          }}
                                        >
                                          <i className="ki-outline ki-plus fs-3 text-danger" />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          "No Data"
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade  ${
                        all_common_hide_show?.auto_negative_product ===
                        "enter_list"
                          ? "show active"
                          : ""
                      }`}
                    >
                      <div className>
                        <Input.TextArea
                          // onPressEnter={(e) => {
                          //   const finalAsin = e?.target.value?.split(",");
                          //   const data = {
                          //     marketplace_id: selected_marketplace,
                          //     product_asins:
                          //       selected_negative_brand_product?.map((d) => {
                          //         return d?.expression_value;
                          //       }),
                          //     asins: finalAsin.map((str) => str.trim()),
                          //   };
                          //   setLoading(true);
                          //   GetAutoNegativeProduct(data);
                          // }}
                          onChange={(e) => {
                            set_auto_enter_list_val(e.target.value);
                          }}
                          value={auto_enter_list_val}
                          cols={30}
                          rows={10}
                          placeholder="Enter ASINs seprated by comma, space or new line."
                        />
                        <div className="mt-3 text-end">
                          <button
                            className="btn btn-dark fs-7"
                            disabled={loading}
                            onClick={() => {
                              const finalAsin = auto_enter_list_val?.split(",");
                              const data = {
                                marketplace_id: selected_marketplace,
                                product_asins:
                                  selected_negative_brand_product?.map((d) => {
                                    return d?.expression_value;
                                  }),
                                asins: finalAsin.map((str) => str.trim()),
                              };
                              setLoading(true);
                              GetAutoNegativeEnterListProduct(data);
                            }}
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm align-middle me-2" />
                            )}
                            Add
                          </button>
                        </div>
                        {inValidAsin?.length > 0 && (
                          <div className="row px-0 mx-0">
                            <div className="col-12 px-0 mx-0">
                              <label className="text-danger fw-bolder fs-6">
                                {inValidAsin?.length}&nbsp; Producs weren't
                                added
                                <span
                                  className="text-info cursor-pointer"
                                  onClick={() => {
                                    // setIsOpenErrorModal(true);
                                  }}
                                >
                                  Click here to view
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade  ${
                        all_common_hide_show?.auto_negative_product === "upload"
                          ? "show active"
                          : ""
                      }`}
                    >
                      <p className="mb-3 mt-2 text-muted">
                        Use the template to make sure your information uploads
                        accurately.
                      </p>
                      <div className="mb-5">
                        <span
                          className="fw-bold text-primary cursor-pointer"
                          onClick={() => {
                            downloadFile(
                              "/assets/media/sample-template/",
                              "product_template.xlsx"
                            );
                          }}
                        >
                          <i className="bi bi-download text-primary me-3" />
                          Download the sample template
                        </span>
                      </div>

                      <div className="fv-row">
                        <div>
                          <Dragger
                            customRequest={customUpload}
                            beforeUpload={beforeUpload}
                            // className="dropzone"
                            // id="kt_dropzonejs_example_1"
                            // style={{ padding: "2px" }}
                          >
                            <div className="dz-message needsclick">
                              {isAutoUploading ? (
                                <LoadingOutlined
                                  style={{ color: "#008000", fontSize: "28px" }}
                                />
                              ) : (
                                <i className="ki-duotone ki-file-up fs-3x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              )}

                              <div className="ms-4">
                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                  Drop files here or click to upload.
                                </h3>
                                <span className="fs-7 fw-semibold text-gray-600">
                                  Accepted formats: CSV, XLSX
                                </span>
                              </div>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-between">
                            <div className="text-start">
                              <p className="ant-upload-text fs-4 fw-bolder mb-0">
                                Drop files here or click to upload xlsx.
                              </p>
                            </div>
                            {isUploading ? (
                              <LoadingOutlined
                                style={{ color: "#008000", fontSize: "28px" }}
                              />
                            ) : (
                              <i className="bi bi-file-earmark-arrow-up fs-3x" />
                            )}
                          </div> */}
                          </Dragger>
                        </div>
                      </div>

                      {inValidAsin?.length > 0 && (
                        <div className="row px-0 mx-0 mt-10">
                          <div className="col-12 px-0 mx-0">
                            <label className="text-danger fw-bolder fs-6">
                              {inValidAsin?.length}&nbsp; Producs weren't added
                              <span
                                className="text-info cursor-pointer"
                                onClick={() => {
                                  // setIsOpenErrorModal(true);
                                }}
                              >
                                Click here to view
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-6">
              <div className="card min-h-450px">
                <div className="card-header ">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      {/* {selected_auto_product_list?.length} Products */}
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <span
                      className="btn fs-7 btn-light-primary fw-bold btn-sm"
                      onClick={() => set_selected_negative_brand_product([])}
                    >
                      Remove All
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="mt-0"
                    style={{ height: "371px", overflow: "scroll" }}
                  >
                    {selected_negative_brand_product?.length > 0 ? (
                      selected_negative_brand_product?.map((d, i) => {
                        return d?.type === "products" ? (
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                <img src={d?.image_url} className alt />
                              </div>
                              <div className="d-flex flex-column me-2 fs-7">
                                <div className="d-flex">
                                  <Popover
                                    content={
                                      <div
                                        style={{
                                          height: "max-content",
                                          maxWidth: "400px",
                                        }}
                                      >
                                        {d?.name}
                                      </div>
                                    }
                                    placement="bottom"
                                  >
                                    <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                      {d?.name}
                                    </span>
                                  </Popover>
                                </div>
                                <span className="text-gray-700 fs-8">
                                  ASIN:
                                  <b className="text-primary">
                                    {d?.expression_value}
                                  </b>
                                </span>
                              </div>
                              <div
                                className="fw-bold"
                                onClick={() => {
                                  const updatedList =
                                    selected_negative_brand_product.filter(
                                      (item, index) => index !== i
                                    );
                                  set_selected_negative_brand_product(
                                    updatedList
                                  );
                                }}
                              >
                                <i className="ki-outline ki-cross-square text-primary fs-2" />
                              </div>
                            </div>{" "}
                            <div className="separator separator-dashed border-gray-300 my-3"></div>
                          </div>
                        ) : (
                          <div className="col-12">
                            <div className="d-flex justify-content-between">
                              <div>
                                <div>
                                  <label>{d?.name}</label>
                                </div>
                              </div>
                              <div
                                className="fw-bold"
                                onClick={() => {
                                  const updatedList =
                                    selected_negative_brand_product.filter(
                                      (item, index) => index !== i
                                    );
                                  set_selected_negative_brand_product(
                                    updatedList
                                  );
                                }}
                              >
                                <i className="ki-outline ki-cross-square text-primary fs-2" />
                              </div>
                            </div>
                            <div className="separator separator-dashed border-gray-300 my-3"></div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12">
                        <NoData type="new" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SPStep4;
