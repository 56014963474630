import { lazy } from "react";
import ManageUser from "../modules/pages/admin/manage-user/manage-user";
import Setting from "../modules/pages/setting";
export default [
  {
    path: "/callbackads",
    component: <Setting />,
    exact: true,
  },
  {
    path: "/callbacksp",
    component: <Setting />,
    exact: true,
  },
  {
    path: "/setting",
    component: <Setting />,
    exact: true,
  },
  //   {
  //     path: "/reset-password",
  //     component: <Setting />,
  //     exact: true,
  //   },
  {
    path: "/profile",
    component: <Setting />,
    exact: true,
  },
];
