import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function ({ show, onHide, inValidAsin }) {
  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div style={{ minHeight: "300px" }}>
              <div className="row">
                {inValidAsin?.map((d, i) => {
                  return (
                    <>
                      <div className="col-12">
                        <span className="fw-bold">{d?.asin_not_added}</span> -{" "}
                        <span className="text-danger">{d?.reason}</span>
                      </div>
                      <div className="separator separator-dashed my-2" />
                    </>
                  );
                })}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
