import CentralLog from "../modules/pages/application-logs/central-log";
import SystemErrorLog from "../modules/pages/application-logs/system-error-log";
export default [
  {
    path: "/application-logs/central-log",
    component: <CentralLog />,
    exact: true,
  },
  {
    path: "/application-logs/system-error-log",
    component: <SystemErrorLog />,
    exact: true,
  },
];
