import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Input, Select, Skeleton, Upload } from "antd";
import React from "react";
import NoData from "../../../../../../component/no-data";
import { DisplayNotification, downloadFile } from "../../../../../../config";
const { Dragger } = Upload;
const Setp5ManualKeywordTargeting = (props) => {
  const {
    set_all_common_hide_show,
    all_common_hide_show,
    bid_selection,
    set_bid_selection,
    common_default_bid,
    set_common_default_bid,
    keyword_loading,
    sugg_keyword_list,
    selected_keyword_list,
    set_selected_keyword_list,
    selected_keyword_filterby,
    set_selected_keyword_filterby,
    enterlist_keyword,
    set_enterlist_keyword,
    AddAllKeywords,
    handleUploadKeywordFile,
    isUploading,
    selected_currency_sign,
  } = props;
  return (
    <div className="automaticDiv1">
      <div className="row gy-5 g-xl-5 mt-0  ">
        <div className="col-xxl-6">
          <div className="card">
            <div className="card-header card-header-stretch">
              <div className="card-title fs-5 m-0">
                <h3 className="fw-bold m-0">Keyword Targeting</h3>
              </div>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body pt-2">
              <ul className="nav nav-tabs mb-5 nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                <li
                  className="nav-item"
                  onClick={() => {
                    set_all_common_hide_show({
                      ...all_common_hide_show,
                      manual_keyword_targeting: "suggested",
                    });
                  }}
                >
                  <span
                    className={`nav-link fw-bold text-gray-800 ${
                      all_common_hide_show?.manual_keyword_targeting ===
                      "suggested"
                        ? "active"
                        : ""
                    }`}
                  >
                    Suggested
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    set_all_common_hide_show({
                      ...all_common_hide_show,
                      manual_keyword_targeting: "enter_list",
                    });
                  }}
                >
                  <span
                    className={`nav-link fw-bold text-gray-800 ${
                      all_common_hide_show?.manual_keyword_targeting ===
                      "enter_list"
                        ? "active"
                        : ""
                    }`}
                  >
                    Enter List
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    set_all_common_hide_show({
                      ...all_common_hide_show,
                      manual_keyword_targeting: "upload",
                    });
                  }}
                >
                  <span
                    className={`nav-link fw-bold text-gray-800 ${
                      all_common_hide_show?.manual_keyword_targeting ===
                      "upload"
                        ? "active"
                        : ""
                    }`}
                  >
                    Upload
                  </span>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade cursor-pointer ${
                    all_common_hide_show?.manual_keyword_targeting ===
                    "suggested"
                      ? "show active"
                      : ""
                  }`}
                >
                  <div className="mt-0 ">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <span className="fw-bolder fs-6">Bid</span>
                      </div>
                      <div className="col-md-9">
                        <div className="d-flex">
                          <Select
                            style={{ width: "200px" }}
                            size="medium"
                            value={bid_selection}
                            onChange={(e) => {
                              set_bid_selection(e);
                            }}
                            options={[
                              {
                                label: "Suggested Bid",
                                value: "suggested_bid",
                              },
                              {
                                label: "Custom Bid",
                                value: "custom_bid",
                              },
                              {
                                label: "Default Bid",
                                value: "default_bid",
                              },
                            ]}
                            className="me-2"
                          />
                          {bid_selection !== "suggested_bid" && (
                            <Input
                              size="medium"
                              prefix={selected_currency_sign}
                              style={{ width: "200px" }}
                              value={common_default_bid}
                              onChange={(e) => {
                                set_common_default_bid(e.target.value);
                              }}
                              placeholder={`${
                                bid_selection === "suggested_bid"
                                  ? "Suggested Bid"
                                  : bid_selection === "custom_bid"
                                  ? "Custom Bid"
                                  : "Default Bid"
                              }`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center my-7">
                    <div className="col-md-3">
                      <span className="fw-bolder fs-6">Filter by</span>
                    </div>
                    <div className="col-md-9">
                      <div className=" d-flex gap-5 mt-0">
                        <Checkbox.Group
                          disabled={keyword_loading}
                          options={[
                            {
                              label: "Broad",
                              value: "BROAD",
                            },
                            {
                              label: "Phrase",
                              value: "PHRASE",
                            },
                            {
                              label: "Exact",
                              value: "EXACT",
                            },
                          ]}
                          defaultValue={["Broad"]}
                          value={selected_keyword_filterby}
                          onChange={(e) => {
                            console.log(e, "-e");
                            if (e?.length > 0) {
                              set_selected_keyword_filterby(e);
                            } else {
                              DisplayNotification(
                                "info",
                                "Info",
                                "Please select at least one filter"
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed border-gray-300" />
                  <div className="mt-5 text-end">
                    <button
                      className="btn btn-sm btn-light-primary"
                      onClick={() => {
                        AddAllKeywords();
                      }}
                    >
                      Add all keywords
                    </button>
                  </div>
                  <div className="mt-5">
                    <div
                      className="table-responsive"
                      style={{ height: "400px" }}
                    >
                      {keyword_loading ? (
                        <Skeleton active />
                      ) : sugg_keyword_list?.length > 0 ? (
                        sugg_keyword_list?.map((d) => {
                          const isAdd = selected_keyword_list?.filter(
                            (f) => f.keyword_name === d.keyword
                          );
                          // console.log(isAdd, "-isAdd");
                          return (
                            <div className="bg-light my-2 p-3">
                              <div>
                                <div className=" d-flex align-items-center justify-content-between ">
                                  <div className="fs-7">
                                    Keyword: <b>{d?.keyword}</b>
                                  </div>
                                </div>
                                <div className="separator separator-dashed border-gray-300 mt-3" />
                                <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                                  <div className="min-w-100px ">
                                    Match Type:
                                  </div>
                                  <div className="min-w-70px ">
                                    <b>Broad</b>
                                  </div>
                                  <div className="min-w-70px ">
                                    <b>Phrase</b>
                                  </div>
                                  <div className="min-w-70px ">
                                    <b>Exact</b>
                                  </div>
                                </div>
                                <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                                  <div className="min-w-100px ">Sugg. Bid:</div>
                                  <div className="min-w-70px d-flex">
                                    {selected_keyword_filterby?.includes(
                                      "BROAD"
                                    ) ? (
                                      <>
                                        <b>
                                          {selected_currency_sign}
                                          {d?.BROAD?.suggested_bid || 0}
                                        </b>{" "}
                                        {!isAdd?.[0]?.broad?.name && (
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const newObj = {
                                                keyword_name: d?.keyword,
                                                broad: {
                                                  keyword_name: d?.keyword,
                                                  name: "BROAD",
                                                  old_sugg_bid:
                                                    d?.BROAD?.suggested_bid ||
                                                    0,
                                                  sugg_bid:
                                                    bid_selection ===
                                                    "suggested_bid"
                                                      ? d?.BROAD?.suggested_bid
                                                      : common_default_bid,
                                                  state: "ENABLED",
                                                },
                                              };

                                              set_selected_keyword_list(
                                                (prev) => {
                                                  const existingKeyword =
                                                    prev?.find(
                                                      (sk) =>
                                                        sk?.keyword_name ===
                                                        d?.keyword
                                                    );
                                                  if (existingKeyword) {
                                                    // Update existing keyword
                                                    return prev.map((sk) =>
                                                      sk?.keyword_name ===
                                                      d?.keyword
                                                        ? {
                                                            ...sk,
                                                            ...newObj,
                                                          }
                                                        : sk
                                                    );
                                                  } else {
                                                    // Add new keyword
                                                    return [...prev, newObj];
                                                  }
                                                }
                                              );
                                            }}
                                          >
                                            <i className="ki-outline ki-plus text-primary fs-4" />
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                  <div className="min-w-70px d-flex">
                                    {selected_keyword_filterby?.includes(
                                      "PHRASE"
                                    ) ? (
                                      <>
                                        {" "}
                                        <b>
                                          {selected_currency_sign}
                                          {d?.PHRASE?.suggested_bid || 0}
                                        </b>{" "}
                                        {!isAdd?.[0]?.phrase?.name && (
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const newObj = {
                                                keyword_name: d?.keyword,
                                                phrase: {
                                                  keyword_name: d?.keyword,
                                                  name: "PHRASE",
                                                  old_sugg_bid:
                                                    d?.PHRASE?.suggested_bid ||
                                                    0,
                                                  sugg_bid:
                                                    bid_selection ===
                                                    "suggested_bid"
                                                      ? d?.PHRASE?.suggested_bid
                                                      : common_default_bid,
                                                  state: "ENABLED",
                                                },
                                              };

                                              set_selected_keyword_list(
                                                (prev) => {
                                                  const existingKeyword =
                                                    prev?.find(
                                                      (sk) =>
                                                        sk?.keyword_name ===
                                                        d?.keyword
                                                    );
                                                  if (existingKeyword) {
                                                    // Update existing keyword
                                                    return prev.map((sk) =>
                                                      sk?.keyword_name ===
                                                      d?.keyword
                                                        ? {
                                                            ...sk,
                                                            ...newObj,
                                                          }
                                                        : sk
                                                    );
                                                  } else {
                                                    // Add new keyword
                                                    return [...prev, newObj];
                                                  }
                                                }
                                              );
                                            }}
                                          >
                                            <i className="ki-outline ki-plus text-primary fs-4" />
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                  <div className="min-w-70px d-flex">
                                    {selected_keyword_filterby?.includes(
                                      "EXACT"
                                    ) ? (
                                      <>
                                        <b>
                                          {selected_currency_sign}
                                          {d?.EXACT?.suggested_bid || 0}
                                        </b>{" "}
                                        {!isAdd?.[0]?.exact?.name && (
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const newObj = {
                                                keyword_name: d?.keyword,
                                                exact: {
                                                  keyword_name: d?.keyword,
                                                  name: "EXACT",
                                                  old_sugg_bid:
                                                    d?.EXACT?.suggested_bid ||
                                                    0,
                                                  sugg_bid:
                                                    bid_selection ===
                                                    "suggested_bid"
                                                      ? d?.EXACT?.suggested_bid
                                                      : common_default_bid,
                                                  state: "ENABLED",
                                                },
                                              };

                                              set_selected_keyword_list(
                                                (prev) => {
                                                  const existingKeyword =
                                                    prev?.find(
                                                      (sk) =>
                                                        sk?.keyword_name ===
                                                        d?.keyword
                                                    );
                                                  if (existingKeyword) {
                                                    // Update existing keyword
                                                    return prev.map((sk) =>
                                                      sk?.keyword_name ===
                                                      d?.keyword
                                                        ? {
                                                            ...sk,
                                                            ...newObj,
                                                          }
                                                        : sk
                                                    );
                                                  } else {
                                                    // Add new keyword
                                                    return [...prev, newObj];
                                                  }
                                                }
                                              );
                                            }}
                                          >
                                            <i className="ki-outline ki-plus text-primary fs-4" />
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <NoData type="new" />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade cursor-pointer ${
                    all_common_hide_show?.manual_keyword_targeting ===
                    "enter_list"
                      ? "show active"
                      : ""
                  }`}
                >
                  <div className="mt-0 ">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <span className="fw-bolder fs-6">Bid</span>
                      </div>
                      <div className="col-md-9">
                        <div className="d-flex">
                          <Select
                            style={{ width: "200px" }}
                            size="medium"
                            value={bid_selection}
                            onChange={(e) => {
                              set_bid_selection(e);
                            }}
                            options={[
                              {
                                label: "Suggested Bid",
                                value: "suggested_bid",
                              },
                              {
                                label: "Custom Bid",
                                value: "custom_bid",
                              },
                              {
                                label: "Default Bid",
                                value: "default_bid",
                              },
                            ]}
                            className="me-2"
                          />
                          {bid_selection !== "suggested_bid" && (
                            <Input
                              size="medium"
                              prefix={selected_currency_sign}
                              style={{ width: "200px" }}
                              value={common_default_bid}
                              onChange={(e) => {
                                set_common_default_bid(e.target.value);
                              }}
                              placeholder={`${
                                bid_selection === "suggested_bid"
                                  ? "Suggested Bid"
                                  : bid_selection === "custom_bid"
                                  ? "Custom Bid"
                                  : "Default Bid"
                              }`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center my-7">
                    <div className="col-md-3">
                      <span className="fw-bolder fs-6">Filter by</span>
                    </div>
                    <div className="col-md-9">
                      <div className=" d-flex gap-5 mt-0">
                        <Checkbox.Group
                          disabled={keyword_loading}
                          options={[
                            {
                              label: "Broad",
                              value: "BROAD",
                            },
                            {
                              label: "Phrase",
                              value: "PHRASE",
                            },
                            {
                              label: "Exact",
                              value: "EXACT",
                            },
                          ]}
                          defaultValue={["Broad"]}
                          value={selected_keyword_filterby}
                          onChange={(e) => {
                            console.log(e, "-e");
                            if (e?.length > 0) {
                              set_selected_keyword_filterby(e);
                            } else {
                              DisplayNotification(
                                "info",
                                "Info",
                                "Please select at least one filter"
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed border-gray-300 mb-5" />
                  <div className>
                    <Input.TextArea
                      className="fs-7"
                      cols={30}
                      rows={10}
                      placeholder="Enter ASINs seprated by comma, space or new line."
                      value={enterlist_keyword}
                      onChange={(e) => {
                        set_enterlist_keyword(e.target.value);
                      }}
                    />
                    <div className="mt-3 text-end">
                      <button
                        className="btn btn-dark fs-7"
                        onClick={() => {
                          const isAdd = selected_keyword_list?.filter(
                            (f) => f.keyword_name === enterlist_keyword
                          );
                          if (
                            !isAdd?.[0]?.broad?.name &&
                            selected_keyword_filterby?.includes("BROAD")
                          ) {
                            const newObj = {
                              keyword_name: enterlist_keyword,
                              broad: {
                                keyword_name: enterlist_keyword,
                                name: "BROAD",
                                old_sugg_bid: common_default_bid,
                                sugg_bid: common_default_bid,
                                state: "ENABLED",
                              },
                            };

                            set_selected_keyword_list((prev) => {
                              const existingKeyword = prev?.find(
                                (sk) => sk?.keyword_name === enterlist_keyword
                              );
                              if (existingKeyword) {
                                // Update existing keyword
                                return prev.map((sk) =>
                                  sk?.keyword_name === enterlist_keyword
                                    ? {
                                        ...sk,
                                        ...newObj,
                                      }
                                    : sk
                                );
                              } else {
                                // Add new keyword
                                return [...prev, newObj];
                              }
                            });
                          }
                          if (
                            !isAdd?.[0]?.phrase?.name &&
                            selected_keyword_filterby?.includes("PHRASE")
                          ) {
                            const newObj = {
                              keyword_name: enterlist_keyword,
                              phrase: {
                                keyword_name: enterlist_keyword,
                                name: "PHRASE",
                                old_sugg_bid: common_default_bid,
                                sugg_bid: common_default_bid,
                                state: "ENABLED",
                              },
                            };

                            set_selected_keyword_list((prev) => {
                              const existingKeyword = prev?.find(
                                (sk) => sk?.keyword_name === enterlist_keyword
                              );
                              if (existingKeyword) {
                                // Update existing keyword
                                return prev.map((sk) =>
                                  sk?.keyword_name === enterlist_keyword
                                    ? {
                                        ...sk,
                                        ...newObj,
                                      }
                                    : sk
                                );
                              } else {
                                // Add new keyword
                                return [...prev, newObj];
                              }
                            });
                          }
                          if (
                            !isAdd?.[0]?.exact?.name &&
                            selected_keyword_filterby?.includes("EXACT")
                          ) {
                            const newObj = {
                              keyword_name: enterlist_keyword,
                              exact: {
                                keyword_name: enterlist_keyword,
                                name: "EXACT",
                                old_sugg_bid: common_default_bid,
                                sugg_bid: common_default_bid,
                                state: "ENABLED",
                              },
                            };

                            set_selected_keyword_list((prev) => {
                              const existingKeyword = prev?.find(
                                (sk) => sk?.keyword_name === enterlist_keyword
                              );
                              if (existingKeyword) {
                                // Update existing keyword
                                return prev.map((sk) =>
                                  sk?.keyword_name === enterlist_keyword
                                    ? {
                                        ...sk,
                                        ...newObj,
                                      }
                                    : sk
                                );
                              } else {
                                // Add new keyword
                                return [...prev, newObj];
                              }
                            });
                          }
                          set_enterlist_keyword("");
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade cursor-pointer ${
                    all_common_hide_show?.manual_keyword_targeting === "upload"
                      ? "show active"
                      : ""
                  }`}
                >
                  <p className="mb-3 mt-2 text-muted">
                    Use the template to make sure your information uploads
                    accurately.
                  </p>
                  <div className="mb-5">
                    <span
                      className="fw-bold text-primary cursor-pointer"
                      onClick={() => {
                        downloadFile(
                          "/assets/media/sample-template/",
                          "keyword_targeting_template.xlsx"
                        );
                      }}
                    >
                      <i className="bi bi-download text-primary me-2" />
                      Download the sample template
                    </span>
                  </div>

                  <div className="form">
                    <div className="fv-row">
                      <div>
                        <Dragger
                          customRequest={({ file, onSuccess }) => {
                            // Simulate a file upload (do nothing here)
                            setTimeout(() => {
                              onSuccess(null, file);
                            }, 0);
                          }}
                          onChange={handleUploadKeywordFile}
                          showUploadList={false}
                        >
                          <div className="dz-message needsclick">
                            {isUploading ? (
                              <LoadingOutlined
                                style={{
                                  color: "#008000",
                                  fontSize: "28px",
                                }}
                              />
                            ) : (
                              <i className="ki-duotone ki-file-up fs-3x text-primary">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            )}

                            <div className="ms-4">
                              <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                Drop files here or click to upload.
                              </h3>
                              <span className="fs-7 fw-semibold text-gray-600">
                                Accepted formats: CSV, XLSX
                              </span>
                            </div>
                          </div>
                        </Dragger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-6">
          <div className="card" style={{ minHeight: "670px" }}>
            <div className="card-header ">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Keywords Targeting List</h3>
              </div>
              <div className="card-toolbar">
                <span
                  onClick={() => set_selected_keyword_list([])}
                  className="btn fs-7 btn-light-primary fw-bold btn-sm"
                >
                  Remove All
                </span>
              </div>
            </div>
            <div className="card-body p-5">
              <div className="mt-5">
                <div className="table-responsive" style={{ height: "550px" }}>
                  {selected_keyword_list?.length == 0 ? (
                    <NoData type="new" />
                  ) : (
                    selected_keyword_list?.map((d, i) => {
                      return (
                        <div className="bg-light my-2 p-3">
                          <div>
                            <div className=" d-flex align-items-center justify-content-between ">
                              <div className="fs-7">
                                Keyword: <b>{d?.keyword_name}</b>
                              </div>
                            </div>
                            <div className="separator separator-dashed border-gray-300 mt-3" />
                            <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                              <div className="min-w-100px ">Match Type:</div>
                              <div className="min-w-70px ">
                                <b>Broad</b>
                              </div>
                              <div className="min-w-70px ">
                                <b>Phrase</b>
                              </div>
                              <div className="min-w-70px ">
                                <b>Exact</b>
                              </div>
                            </div>
                            <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                              <div className="min-w-100px ">Sugg. Bid:</div>
                              <div className="min-w-70px d-flex">
                                <div
                                  style={{
                                    border: "1px solid #dadada",
                                    borderRadius: "5px",
                                  }}
                                  className="p-3 position-relative"
                                >
                                  {d?.broad?.old_sugg_bid ? (
                                    <>
                                      {" "}
                                      <b>
                                        {selected_currency_sign}
                                        {d?.broad?.old_sugg_bid || 0}
                                      </b>
                                      <div className="mt-2">
                                        <Input
                                          style={{
                                            width: "70px",
                                            padding: "3px 5px",
                                          }}
                                          value={d?.broad?.sugg_bid}
                                          onChange={(e) => {
                                            set_selected_keyword_list(
                                              (prev) => {
                                                return prev?.map((sk, j) => {
                                                  if (j == i) {
                                                    return {
                                                      ...sk,
                                                      ...(sk.broad.sugg_bid =
                                                        e.target.value),
                                                    };
                                                  } else {
                                                    return { ...sk };
                                                  }
                                                });
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="text-center"
                                        style={{
                                          position: "absolute",
                                          top: "-8px",
                                          right: "-9px",
                                        }}
                                      >
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            const after_deleted_list =
                                              selected_keyword_list.map(
                                                (item) => {
                                                  if (
                                                    item.keyword_name ===
                                                    d?.keyword_name
                                                  ) {
                                                    const { broad, ...rest } =
                                                      item;
                                                    return rest;
                                                  }
                                                  return item;
                                                }
                                              );
                                            const filteredArray =
                                              after_deleted_list.filter(
                                                (d) =>
                                                  d?.hasOwnProperty("broad") ||
                                                  d?.hasOwnProperty("phrase") ||
                                                  d?.hasOwnProperty("exact")
                                              );

                                            set_selected_keyword_list(
                                              filteredArray
                                            );
                                          }}
                                        >
                                          <i className="ki-outline ki-cross-square text-primary fs-2" />
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                              <div className="min-w-70px d-flex">
                                <div
                                  style={{
                                    border: "1px solid #dadada",
                                    borderRadius: "5px",
                                  }}
                                  className="p-3 position-relative"
                                >
                                  {d?.phrase?.old_sugg_bid ? (
                                    <>
                                      {" "}
                                      <b>
                                        {selected_currency_sign}
                                        {d?.phrase?.old_sugg_bid || 0}
                                      </b>{" "}
                                      <div className="mt-2">
                                        <Input
                                          style={{
                                            width: "70px",
                                            padding: "3px 5px",
                                          }}
                                          value={d?.phrase?.sugg_bid}
                                          onChange={(e) => {
                                            set_selected_keyword_list(
                                              (prev) => {
                                                return prev?.map((sk, j) => {
                                                  if (j == i) {
                                                    return {
                                                      ...sk,
                                                      ...(sk.phrase.sugg_bid =
                                                        e.target.value),
                                                    };
                                                  } else {
                                                    return { ...sk };
                                                  }
                                                });
                                              }
                                            );
                                          }}
                                        />

                                        <div
                                          className="text-center"
                                          style={{
                                            position: "absolute",
                                            top: "-8px",
                                            right: "-9px",
                                          }}
                                        >
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const after_deleted_list =
                                                selected_keyword_list.map(
                                                  (item) => {
                                                    if (
                                                      item.keyword_name ===
                                                      d?.keyword_name
                                                    ) {
                                                      const {
                                                        phrase,
                                                        ...rest
                                                      } = item;
                                                      return rest;
                                                    }
                                                    return item;
                                                  }
                                                );

                                              const filteredArray =
                                                after_deleted_list.filter(
                                                  (d) =>
                                                    d?.hasOwnProperty(
                                                      "broad"
                                                    ) ||
                                                    d?.hasOwnProperty(
                                                      "phrase"
                                                    ) ||
                                                    d?.hasOwnProperty("exact")
                                                );

                                              set_selected_keyword_list(
                                                filteredArray
                                              );
                                            }}
                                          >
                                            <i className="ki-outline ki-cross-square text-primary fs-4" />
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                              <div className="min-w-70px d-flex">
                                <div
                                  style={{
                                    border: "1px solid #dadada",
                                    borderRadius: "5px",
                                  }}
                                  className="p-3 position-relative"
                                >
                                  {d?.exact?.old_sugg_bid ||
                                  d?.exact?.old_sugg_bid == 0 ? (
                                    <>
                                      <b>
                                        {selected_currency_sign}
                                        {d?.exact?.old_sugg_bid || 0}
                                      </b>
                                      <div className="mt-2">
                                        <Input
                                          style={{
                                            width: "70px",
                                            padding: "3px 5px",
                                          }}
                                          value={d?.exact?.sugg_bid}
                                          onChange={(e) => {
                                            set_selected_keyword_list(
                                              (prev) => {
                                                return prev?.map((sk, j) => {
                                                  if (j == i) {
                                                    return {
                                                      ...sk,
                                                      ...(sk.exact.sugg_bid =
                                                        e.target.value),
                                                    };
                                                  } else {
                                                    return { ...sk };
                                                  }
                                                });
                                              }
                                            );
                                          }}
                                        />
                                        <div
                                          className="text-center"
                                          style={{
                                            position: "absolute",
                                            top: "-8px",
                                            right: "-9px",
                                          }}
                                        >
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const after_deleted_list =
                                                selected_keyword_list.map(
                                                  (item) => {
                                                    if (
                                                      item.keyword_name ===
                                                      d?.keyword_name
                                                    ) {
                                                      const { exact, ...rest } =
                                                        item;
                                                      return rest;
                                                    }
                                                    return item;
                                                  }
                                                );

                                              const filteredArray =
                                                after_deleted_list.filter(
                                                  (d) =>
                                                    d?.hasOwnProperty(
                                                      "broad"
                                                    ) ||
                                                    d?.hasOwnProperty(
                                                      "phrase"
                                                    ) ||
                                                    d?.hasOwnProperty("exact")
                                                );

                                              set_selected_keyword_list(
                                                filteredArray
                                              );
                                            }}
                                          >
                                            <i className="ki-outline ki-cross-square text-primary fs-4" />
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setp5ManualKeywordTargeting;
