import styled from "styled-components";

export const CreateCampaignWrapper = styled.div`
  .ant-checkbox .ant-checkbox-inner {
    width: 22px !important;
    height: 22px !important;
  }
  .ant-checkbox .ant-checkbox-inner:after {
    width: 5.714286px !important;
    height: 11.142857px !important;
  }
  .ant-radio-wrapper .ant-radio-inner {
    width: 22px !important;
    height: 22px !important;
  }
  .ant-radio-wrapper .ant-radio-inner::after {
    width: 28px !important;
    height: 28px !important;
    margin-block-start: -14px !important;
    margin-inline-start: -14px !important;
  }
`;

export const AdGroupWrapper = styled.div`
  .ant-checkbox .ant-checkbox-inner {
    width: 22px !important;
    height: 22px !important;
  }
  .ant-checkbox .ant-checkbox-inner:after {
    width: 5.714286px !important;
    height: 11.142857px !important;
  }
  .ant-radio-wrapper .ant-radio-inner {
    width: 22px !important;
    height: 22px !important;
  }
  .ant-radio-wrapper .ant-radio-inner::after {
    width: 28px !important;
    height: 28px !important;
    margin-block-start: -14px !important;
    margin-inline-start: -14px !important;
  }
`;
