import React from "react";
import { Modal } from "react-bootstrap";
import EsfTable from "../../../../component/esf-table";
export default function ({ currency_sign, show, onHide, activeKeywordList }) {
  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (text) => {
        return (
          <span className="fw-bold" style={{ textTransform: "capitalize" }}>
            {text?.toLowerCase()?.replaceAll("-", " ") || "-"}
          </span>
        );
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: (text) => {
        return (
          <span>
            {currency_sign}
            {text || "-"}
          </span>
        );
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (text) => {
        return (
          <span>
            {currency_sign}
            {text || "-"}
          </span>
        );
      },
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      render: (text) => {
        return (
          <span>
            {currency_sign}
            {text || "-"}
          </span>
        );
      },
    },
    {
      title: "ACoS Percentage",
      dataIndex: "ACoS_percentage",
      key: "ACoS_percentage",
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (text) => {
        return (
          <span>
            {currency_sign}
            {text || "-"}
          </span>
        );
      },
    },
    {
      title: "CPO",
      dataIndex: "CPO",
      key: "CPO",
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (text) => {
        return <span>{text || "-"}%</span>;
      },
    },
    {
      title: "CVR",
      dataIndex: "CVR",
      key: "CVR",
    },
    {
      title: "ROAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (text) => {
        return (
          <span>
            {currency_sign}
            {text || "-"}
          </span>
        );
      },
    },
    {
      title: "Ad Keyword Status",
      dataIndex: "ad_keyword_status",
      key: "ad_keyword_status",
    },
    {
      title: "Keyword ID",
      dataIndex: "keyword_id",
      key: "keyword_id",
    },
    {
      title: "Keyword Type",
      dataIndex: "keyword_type",
      key: "keyword_type",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
    },
    {
      title: "Unit Ordered",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
    },
  ];

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Active Keyword</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <EsfTable
                columns={columns}
                fixed="top"
                dataSource={activeKeywordList}
                rowKey="key"
                // loading={loading}
                pagination={true}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
