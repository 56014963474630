import { Dropdown, Input, Radio, Select, Space, theme } from "antd";
import React, { useEffect, useState } from "react";
import {
  DisplayNotification,
  NumberWithCommas,
} from "../../../../../../config";
import { campaignBiddingStrategy } from "../../../../../../config/select-dropdown-list";
import { MakeApiCall } from "../../../../../../apis/make-api-call";

const SPStep3 = (props) => {
  const {
    campaign_data_list,
    selected_adgroup_list,
    set_selected_adgroup_list,
    set_auto_targeting_radio,
    auto_targeting_radio,
    ad_group_default_bid,
    set_ad_group_default_bid,
    ad_group_product_targeting,
    set_ad_group_product_targeting,
    selected_product_list,
    set_campaign_bidding_strategy_label,
    updateCampaignDataList,
    set_common_default_bid,
    selected_marketplace,
    selected_currency_sign,
  } = props;
  const [adgroup_input, set_adgroup_input] = useState("");
  const [bid_loading, set_bid_loading] = useState(true);
  const [default_bid_data, set_default_bid_data] = useState({});
  const [topOfSearchVal, setTopOfSearchVal] = useState({});
  const [restOfSearchVal, setRestOfSearchVal] = useState({});
  const [productPagesSearchVal, setProductPagesSearchVal] = useState({});

  useEffect(() => {
    // if (campaign_data_list?.targeting_type === "AUTO") {
    const asinsList = selected_product_list?.map((d) => {
      return d?.asin1;
    });
    const bidData = {
      marketplace_id: selected_marketplace,
      bidding_strategy: campaign_data_list?.bidding_strategy,
      asins: asinsList,
    };
    set_bid_loading(true);
    GetSuggestedBid(bidData);
    // }
  }, []);

  useEffect(() => {
    getTopOfSearchValue();
    getRestOfSearchValue();
    getProductPagesPlacementValue();
  }, [
    ad_group_default_bid,
    campaign_data_list?.top_of_search_placement,
    campaign_data_list?.rest_of_search_placement,
    campaign_data_list?.product_pages_placement,
  ]);

  const GetSuggestedBid = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-bid-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      set_bid_loading(false);
      const newData = response?.data?.bids_by_targeting_group?.map((d) => {
        return { ...d, static_bid: d?.suggested_bid };
      });
      set_ad_group_product_targeting(newData || []);
      set_ad_group_default_bid(response?.data?.default_bid?.suggested_bid);
      set_common_default_bid(response?.data?.default_bid?.suggested_bid);
      set_default_bid_data(response?.data?.default_bid || 0);
    } else {
      set_bid_loading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const getTopOfSearchValue = () => {
    const ans1 =
      parseFloat(ad_group_default_bid) *
      campaign_data_list?.top_of_search_placement;
    const ans2 = ans1 / 100;
    const ans3 = ans2 + parseFloat(ad_group_default_bid);
    const ans4 = ans3 * 2;
    setTopOfSearchVal({
      val1: Math.round(ans3 * 100) / 100,
      val2: Math.round(ans4 * 100) / 100,
    });
  };

  const getRestOfSearchValue = () => {
    const ans1 =
      parseFloat(ad_group_default_bid) *
      campaign_data_list?.rest_of_search_placement;
    const ans2 = ans1 / 100;
    const ans3 = ans2 + parseFloat(ad_group_default_bid);
    const ans4 = ans3 / 2;
    const ans5 = ans4 + ans3;
    setRestOfSearchVal({
      val1: Math.round(ans3 * 100) / 100,
      val2: Math.round(ans5 * 100) / 100,
    });
  };

  const getProductPagesPlacementValue = () => {
    const ans1 =
      parseFloat(ad_group_default_bid) *
      campaign_data_list?.product_pages_placement;
    const ans2 = ans1 / 100;
    const ans3 = ans2 + parseFloat(ad_group_default_bid);
    const ans4 = ans3 / 2;
    const ans5 = ans4 + ans3;
    setProductPagesSearchVal({
      val1: Math.round(ans3 * 100) / 100,
      val2: Math.round(ans5 * 100) / 100,
    });
  };

  return (
    <>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className="row gy-5 g-xl-5">
          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Ad Group Name</h3>
                </div>

                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="mb-0 d-flex flex-wrap">
                  <div className="my-1 me-10">
                    <label className="col-form-label required fw-semibold fs-6">
                      Ad Group Name
                    </label>
                  </div>
                  <div className="d-flex mx-2 my-1 ">
                    <div>
                      <Input
                        placeholder="Enter Ad Group Name"
                        size="large"
                        style={{ width: "300px" }}
                        value={adgroup_input}
                        disabled={selected_adgroup_list?.length > 0}
                        onPressEnter={(e) => {
                          if (e.target.value) {
                            const isExistAdgoup = selected_adgroup_list?.filter(
                              (f) => f === e.target.value
                            );
                            console.log(isExistAdgoup, "-isExistAdgoup");
                            if (isExistAdgoup?.length > 0) {
                              DisplayNotification(
                                "info",
                                "Info",
                                "Ad group already exist"
                              );
                            } else {
                              set_selected_adgroup_list([
                                ...selected_adgroup_list,
                                e.target.value,
                              ]);
                              setTimeout(() => {
                                set_adgroup_input("");
                              }, 600);
                            }
                          } else {
                            DisplayNotification(
                              "error",
                              "Error",
                              "Please add ad group"
                            );
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value) {
                            const isExistAdgoup = selected_adgroup_list?.filter(
                              (f) => f === e.target.value
                            );
                            console.log(isExistAdgoup, "-isExistAdgoup");
                            if (isExistAdgoup?.length > 0) {
                              DisplayNotification(
                                "info",
                                "Info",
                                "Ad group already exist"
                              );
                            } else {
                              set_selected_adgroup_list([
                                ...selected_adgroup_list,
                                e.target.value,
                              ]);
                              setTimeout(() => {
                                set_adgroup_input("");
                              }, 600);
                            }
                          } else {
                            DisplayNotification(
                              "error",
                              "Error",
                              "Please add ad group"
                            );
                          }
                        }}
                        onChange={(e) => {
                          set_adgroup_input(e.target.value);
                        }}
                      />
                    </div>
                    {/* <span
                      className="btn btn-icon btn-primary btn-outline w-40px h-40px position-relative border-0 ms-2"
                      onClick={() => {
                        if (adgroup_input) {
                          set_selected_adgroup_list([
                            ...selected_adgroup_list,
                            adgroup_input,
                          ]);
                        } else {
                          DisplayNotification(
                            "error",
                            "Error",
                            "Please add ad group"
                          );
                        }
                      }}
                    >
                      <i className="ki-outline ki-plus fs-2" />
                    </span> */}
                  </div>
                </div>
                {selected_adgroup_list?.length > 0 && (
                  <>
                    <div className="separator border-gray-200 my-5" />
                    <div className="row">
                      <label className="col-form-label fw-bold fs-5">
                        Ad Group List
                      </label>
                    </div>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        {selected_adgroup_list?.map((d, i) => {
                          return (
                            <div>
                              <span
                                className="d-flex align-items-center my-1 mx-1"
                                style={{
                                  border: "1px solid #adc6ff",
                                  color: "#035091",
                                  padding: "1px 8px",
                                  borderRadius: "5px",
                                  background: "#f0f5ff",
                                }}
                              >
                                <span className="me-2"> {d}</span>
                                <i
                                  className="ki-outline ki-cross-square text-danger fs-4 cursor-pointer"
                                  style={{ marginTop: "1px" }}
                                  onClick={() => {
                                    const removeAdGroup =
                                      selected_adgroup_list?.filter(
                                        (f, index) => index !== i
                                      );

                                    set_selected_adgroup_list(removeAdGroup);
                                  }}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {campaign_data_list?.targeting_type === "AUTO" && (
            <div className="col-xl-12 automaticDiv">
              <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                <div className="card-header border-0 cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Auto Targeting</h3>
                  </div>
                </div>

                <div className="card-body border-top border-top-dashed pb-2 pt-6">
                  <div className="row g-5 align-items-center">
                    <div className="col-md-12">
                      <div className="d-flex gap-4 align-items-center">
                        <Radio.Group
                          name="radiogroup"
                          onChange={(e) => {
                            set_auto_targeting_radio(e.target.value);
                          }}
                          value={auto_targeting_radio}
                        >
                          <Radio value={"default_bid"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Set Default Bid
                              </span>
                            </span>
                          </Radio>
                          <Radio value={"bid_by_targeting"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Set bids by targeting groups
                              </span>
                            </span>
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed mt-4" />
                  <div className="row g-5 align-items-center mt-0">
                    <div className="col-md-12">
                      {auto_targeting_radio === "default_bid" && (
                        <div className="Default" style={{ display: "block" }}>
                          <div className="row g-5">
                            <div className="col-md-4">
                              <select
                                name
                                id
                                className="form-select fs-7"
                                disabled
                              >
                                <option value>
                                  Suggested bid for regular days
                                </option>
                              </select>
                            </div>
                            <div className="col-4 text-center">
                              <div>
                                <span className="fw-bold fs-5">
                                  {selected_currency_sign}
                                  {default_bid_data?.suggested_bid}
                                </span>
                                <div>
                                  {`(${selected_currency_sign}`}
                                  {default_bid_data?.min_bid} {"-"}{" "}
                                  {selected_currency_sign}
                                  {default_bid_data?.max_bid}
                                  {")"}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="ms-4">
                                <div className="">
                                  <Input
                                    value={ad_group_default_bid}
                                    onChange={(e) => {
                                      const newVal = e.target.value
                                        .split(".")
                                        .join("");
                                      const isNumeric = /^[0-9]+$/.test(newVal);
                                      if (isNumeric || e.target.value === "") {
                                        set_ad_group_default_bid(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    addonBefore={
                                      <span className=" bg-white fw-bolder fs-5">
                                        {selected_currency_sign}
                                      </span>
                                    }
                                    size="large"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {auto_targeting_radio === "bid_by_targeting" && (
                        <div className="SetBid">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark ">
                                <th>Targeting Groups</th>
                                <th>Suggested bid for regular days</th>
                                <th className="w-200px">Bid</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ad_group_product_targeting?.map((d) => {
                                return (
                                  <tr>
                                    <td>
                                      <div className="form-check form-switch form-check-custom form-check-solid">
                                        {/* <input
                                          className="form-check-input h-20px w-30px"
                                          type="checkbox"
                                          defaultValue
                                          id="flexSwitchDefault"
                                        /> */}
                                        <label
                                          className="form-check-label text-gray-800 fs-6 fw-bold"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {d?.targeting_expression_type
                                            ?.replaceAll("_", " ")
                                            ?.toLowerCase()}
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="fw-bold fs-5">
                                        {selected_currency_sign}
                                        {d?.static_bid}
                                      </span>
                                      <div>
                                        {`(${selected_currency_sign}`}
                                        {d?.min_bid} {"-"}{" "}
                                        {selected_currency_sign}
                                        {d?.max_bid}
                                        {")"}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <Input
                                          value={d?.suggested_bid}
                                          onChange={(e) => {
                                            const newVal = e.target.value
                                              .split(".")
                                              .join("");
                                            const isNumeric = /^[0-9]+$/.test(
                                              newVal
                                            );
                                            if (
                                              isNumeric ||
                                              e.target.value === ""
                                            ) {
                                              set_ad_group_product_targeting(
                                                (prev) => {
                                                  return prev?.map((a) => {
                                                    if (
                                                      a?.targeting_expression_type ===
                                                      d?.targeting_expression_type
                                                    ) {
                                                      return {
                                                        ...d,
                                                        suggested_bid:
                                                          e.target.value,
                                                      };
                                                    } else {
                                                      return a;
                                                    }
                                                  });
                                                }
                                              );
                                            }
                                          }}
                                          addonBefore={
                                            <span className=" bg-white fw-bolder fs-5">
                                              {selected_currency_sign}
                                            </span>
                                          }
                                          size="large"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {console.log(
                        ad_group_product_targeting,
                        "-ad_group_product_targeting"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row mt-5">
          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Campaign Bidding Strategy</h3>
                </div>
                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="d-flex w-100 mb-7">
                  {/* <Select
                    options={campaignBiddingStrategy?.map((d) => {
                      return d;
                    })}
                  /> */}
                  <div className="row">
                    {campaignBiddingStrategy?.map((d) => {
                      return (
                        <Radio.Group
                          key={d?.key}
                          onChange={(e) => {
                            set_campaign_bidding_strategy_label(
                              d?.display_label
                            );
                            updateCampaignDataList(
                              "bidding_strategy",
                              e.target.value
                            );
                          }}
                          value={
                            campaign_data_list?.bidding_strategy === d?.key
                              ? d?.key
                              : ""
                          }
                        >
                          <Space direction="vertical">
                            <Radio key={d?.key} className="mb-2" value={d?.key}>
                              <div style={{ padding: "10px" }}>
                                <div className="d-flex align-items-center w-100">
                                  <div className="d-flex flex-column w-100">
                                    <span
                                      className={`fs-6 mb-2 fw-bold lh-1 ${
                                        d?.key ===
                                        campaign_data_list?.bidding_strategy
                                          ? "text-primary"
                                          : ""
                                      }`}
                                    >
                                      {d?.display_label?.header}
                                    </span>
                                    <span className="text-gray-600 fs-8 w-75">
                                      {d?.display_label?.content}
                                    </span>
                                  </div>
                                </div>
                                {/* <div></div> */}
                              </div>
                              <div className="separator border-gray-200" />
                            </Radio>
                          </Space>
                        </Radio.Group>
                      );
                    })}
                  </div>
                  {console.log(
                    campaign_data_list,
                    "-campaign_data_listcampaign_data_listcampaign_data_listcampaign_data_list"
                  )}
                  {/* <Dropdown
                      trigger={["hover"]}
                      placement="bottom"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      dropdownRender={() => (
                        <div style={contentStyle}>
                          <div>
                            <div className="separator border-gray-200" />
                            <div
                              style={{
                                minHeight: "230px",
                                minWidth: "150px",
                                border: "1px solid #fff",
                                borderRadius: "7px",
                                overflow: "auto",
                                background: "#fff",
                                boxShadow: "#cccccc 0px 0px 12px 2px",
                              }}
                            >
                              {campaignBiddingStrategy?.map((d) => {
                                console.log(d, "-display_label");
                                return (
                                  <div
                                    className=""
                                    onClick={() => {
                                      set_campaign_bidding_strategy_label(
                                        d?.display_label
                                      );
                                      updateCampaignDataList(
                                        "bidding_strategy",
                                        d?.key
                                      );
                                    }}
                                  >
                                    <div style={{ padding: "10px" }}>
                                      <div className="d-flex align-items-center w-100">
                                        <div className="d-flex flex-column w-100">
                                          <span
                                            className={`fs-6 mb-2 fw-bold lh-1 ${
                                              d?.key ===
                                              campaign_data_list?.bidding_strategy
                                                ? "text-primary"
                                                : ""
                                            }`}
                                          >
                                            {d?.display_label?.header}
                                          </span>
                                          <span className="text-gray-600 fs-8 w-75">
                                            {d?.display_label?.content}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="separator border-gray-200" />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    >
                      <div
                        className="border rounded w-100 border-dashed border-primary"
                        id=""
                      >
                        <div style={{ padding: "10px" }}>
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex flex-column w-100">
                              <span className="fs-6 mb-2 fw-bold lh-1">
                                {campaign_bidding_strategy_label?.header}
                              </span>
                              <span className="text-gray-600 fs-8 w-75">
                                {campaign_bidding_strategy_label?.content}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown> */}
                  {/* <div className="border rounded w-100 border-dashed border-primary">
                    <select
                      id
                      className="kt_docs_select2_rich_content form-select form-select-transparent"
                      name="..."
                      data-placeholder="..."
                    >
                      <option />
                      <option
                        value={0}
                        data-kt-rich-content-subcontent="We'll raise your bids (by a maximum of 100%) in real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale."
                        selected
                      >
                        Dynamic Bids - Up and Down
                      </option>
                      <option
                        value={1}
                        data-kt-rich-content-subcontent="We'll lower your bids in real time when your ad may be less likely to convert to a sale."
                      >
                        Dynamic Bids - Down only
                      </option>
                      <option
                        value={1}
                        data-kt-rich-content-subcontent="We'll use your exact bid and any manual adjustments you set, and won't change your bids based on likelihood of a sale."
                      >
                        Fixed Bid
                      </option>
                    </select>
                  </div> */}
                </div>

                <div className="row mb-0 ">
                  <div className="col-lg-12">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4">
                      <thead>
                        <tr className="fw-bolder text-dark ">
                          <th className="fs-6" colSpan={3}>
                            Adjust Bids by Placement
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="min-w-200px">
                            <label
                              className="form-check-label text-gray-800 fs-6 fw-bold"
                              htmlFor
                            >
                              Top of search (First Page)
                            </label>
                          </td>
                          <td className="min-w-200px  text-center">
                            <div className="w-100px mx-auto">
                              <Input
                                value={
                                  campaign_data_list?.top_of_search_placement
                                }
                                onChange={(e) => {
                                  const newVal = e.target.value
                                    .split(".")
                                    .join("");
                                  const isNumeric = /^[0-9]+$/.test(newVal);
                                  if (isNumeric || e.target.value === "") {
                                    updateCampaignDataList(
                                      "top_of_search_placement",
                                      e.target.value
                                    );
                                  }
                                }}
                                addonAfter={
                                  <span className=" bg-white fw-bolder fs-5">
                                    %
                                  </span>
                                }
                                size="large"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="fs-7 text-gray-600">
                              Example: A
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {ad_group_default_bid}
                              </span>
                              bid will remain
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {topOfSearchVal?.val1 || 0}
                              </span>
                              for this placement, Dynamic bidding could increase
                              it up to
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {topOfSearchVal?.val2 || 0}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="min-w-200px">
                            <label
                              className="form-check-label text-gray-800 fs-6 fw-bold"
                              htmlFor
                            >
                              Rest of search
                            </label>
                          </td>
                          <td className="min-w-200px  text-center">
                            <div className="w-100px mx-auto">
                              <Input
                                value={
                                  campaign_data_list?.rest_of_search_placement
                                }
                                onChange={(e) => {
                                  const newVal = e.target.value
                                    .split(".")
                                    .join("");
                                  const isNumeric = /^[0-9]+$/.test(newVal);
                                  if (isNumeric || e.target.value === "") {
                                    updateCampaignDataList(
                                      "rest_of_search_placement",
                                      e.target.value
                                    );
                                  }
                                }}
                                addonAfter={
                                  <span className=" bg-white fw-bolder fs-5">
                                    %
                                  </span>
                                }
                                size="large"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="fs-7 text-gray-600">
                              Example: A
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {ad_group_default_bid}
                              </span>{" "}
                              bid will remain
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {restOfSearchVal?.val1 || 0}
                              </span>{" "}
                              for this placement, Dynamic bidding could increase
                              it up to{" "}
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {restOfSearchVal?.val2 || 0}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="min-w-200px">
                            <label
                              className="form-check-label text-gray-800 fs-6 fw-bold"
                              htmlFor
                            >
                              Product Pages
                            </label>
                          </td>
                          <td className="min-w-200px text-center">
                            <div className="w-100px mx-auto">
                              <Input
                                value={
                                  campaign_data_list?.product_pages_placement
                                }
                                onChange={(e) => {
                                  const newVal = e.target.value
                                    .split(".")
                                    .join("");
                                  const isNumeric = /^[0-9]+$/.test(newVal);
                                  if (isNumeric || e.target.value === "") {
                                    updateCampaignDataList(
                                      "product_pages_placement",
                                      e.target.value
                                    );
                                  }
                                }}
                                addonAfter={
                                  <span className=" bg-white fw-bolder fs-5">
                                    %
                                  </span>
                                }
                                size="large"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="fs-7 text-gray-600">
                              Example: A{" "}
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {ad_group_default_bid}
                              </span>{" "}
                              bid will remain{" "}
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {productPagesSearchVal?.val1 || 0}
                              </span>
                              for this placement, Dynamic bidding could increase
                              it up to{" "}
                              <span className="fw-bolder text-dark mx-1">
                                {selected_currency_sign}
                                {productPagesSearchVal?.val2 || 0}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SPStep3;
