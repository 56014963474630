import { message } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const DownloadPDF = async (captureRef) => {
  message.destroy();
  message.loading("Exporting...", 0);
  setTimeout(async () => {
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current, {
        backgroundColor: "#ffffff",
      });
      const image = canvas.toDataURL("image/png");

      // Create an image object
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Set crossOrigin to avoid CORS issues

      // Load the image
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        const pdf = new jsPDF({
          orientation: height < width ? "landscape" : "portrait",
          unit: "pt",
          format: [(img.width / 96) * 72 + 20, (img.height / 96) * 72 + 20],
        });
        // Check if the image fits within the available space on the current page
        pdf.addImage(img, "JPEG", 10, 10);
        // pdf.addImage(img, "JPEG", 5, 10, width, height); // Adjust x, y, width, height as needed

        pdf.save("image_to_pdf.pdf");
      };
      img.src = image;
    }
    message.destroy();
  }, 100);
};
