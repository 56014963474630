// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/manage-user",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-profile-user fs-1"></i>
      </span>
    ),
    label: GetLinks("/manage-user", "Manage User"),
    admin: true,
  },
];
