import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../apis/make-api-call";
import EsfTable from "../../../../component/esf-table";
import Pagination from "../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../component/no-data-table";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
} from "../../../../config";
import DateWiseActionlogSubModal from "./date-wise-actionlog-sub-modal";

export default function (props) {
  const { show, onHide, selectedActionLog } = props;
  console.log(selectedActionLog, "selectedActionLog");

  const [loading, setLoading] = useState(true);
  const [main_log_list, setMain_log_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    master_rule_id: selectedActionLog?.id,
  });

  const [isOpenSubModal, setIsOpenSubModal] = useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [sublogList, setSubLogList] = useState([]);
  const [subTotalPage, setSubTotalPage] = useState(1);
  const [success_failed_all_filters, set_success_failed_all_filters] = useState(
    {
      page: 1,
      "per-page": DefaultPerPage,
      master_rule_id: selectedActionLog?.id,
      date: "",
      type: "",
    }
  );

  useEffect(() => {
    GetActionLogList(all_filters);
  }, []);

  const GetActionLogList = async (filters) => {
    const response = await MakeApiCall(
      `rule-management/get-action-logs${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setMain_log_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetSuccessFailedCount = async (filter) => {
    const response = await MakeApiCall(
      `rule-management/get-applied-rule-logs${ConvertParams(filter)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      if (response?.data?.records?.length > 0) {
        setIsOpenSubModal(true);
        setSubLoading(false);
        setSubLogList(response?.data?.records);
        setSubTotalPage(response?.data?.pagination?.totalCount);
      } else {
        message.destroy();
        DisplayNotification("error", "Error", "No Data Found");
      }
    } else {
      message.destroy();
      setSubLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetActionLogList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetActionLogList(apiObj);
  };

  const mainColumns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (text, __, i) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + i}
            </span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Success Count",
      render: (text) => {
        return (
          <span
            className="text-success fw-bolder cursor-pointer"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetSuccessFailedCount({
                ...success_failed_all_filters,
                date: text?.date,
                type: "success",
              });
            }}
          >
            {text?.success_log || 0}
          </span>
        );
      },
    },
    {
      title: "Failed Count",
      render: (text) => {
        return (
          <span
            className="text-danger fw-bolder cursor-pointer"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetSuccessFailedCount({
                ...success_failed_all_filters,
                date: text?.date,
                type: "failed",
              });
            }}
          >
            {text?.failed_log || 0}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Action Logs - {selectedActionLog?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="table-responsive">
                {loading ? (
                  <NoDataTable columns={mainColumns} type="loading" />
                ) : main_log_list?.length > 0 ? (
                  <EsfTable
                    columns={mainColumns?.map((d) => ({
                      ...d,
                    }))}
                    sticky={{
                      offsetHeader: "10px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 270px)",
                    }}
                    fixed={true}
                    dataSource={main_log_list}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                  />
                ) : (
                  <>
                    <NoDataTable columns={mainColumns} type="no-data" />
                  </>
                )}
              </div>
              <Pagination
                loading={loading || main_log_list?.length === 0}
                pageSize={all_filters?.["per-page"]}
                page={all_filters?.page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {isOpenSubModal && (
        <DateWiseActionlogSubModal
          show={isOpenSubModal}
          onHide={() => {
            setSubLoading(false);
            setSubLogList([]);
            set_success_failed_all_filters({
              page: 1,
              "per-page": DefaultPerPage,
              master_rule_id: selectedActionLog?.id,
              date: "",
              type: "",
            });
            setSubTotalPage(0);
            setIsOpenSubModal(false);
          }}
          data={sublogList}
          subLoading={subLoading}
          setSubLoading={setSubLoading}
          subTotalPage={subTotalPage}
          success_failed_all_filters={success_failed_all_filters}
          set_success_failed_all_filters={set_success_failed_all_filters}
          GetSuccessFailedCount={GetSuccessFailedCount}
        />
      )}
    </>
  );
}
