import React, { useState } from "react";
import { DisplayNotification } from "../../../../../../config";
import { Input, Radio, Select, Switch, Dropdown, theme, Checkbox } from "antd";
import { optimizationStrategy } from "../../../../../../config/select-dropdown-list";
import { AdGroupWrapper } from "./style";
const { useToken } = theme;

const SDAdgroup = (props) => {

    const { all_data,
        set_all_data,
        optimization_strategy_label,
        set_optimization_strategy_label,
        updateOptimizationStrategy,
    } = props;

    const [adgroup_input, set_adgroup_input] = useState("");

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const token = useToken();
    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        minWidth: "150px",
    };
    return (
        <AdGroupWrapper>
            <div
                className="flex-column current"
                data-kt-stepper-element="content"
                style={{ minHeight: 500 }}
            >
                <div className="row gy-5 g-xl-5">
                    <div className="col-xl-12">
                        <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                            <div className="card-header border-0 cursor-pointer">
                                <div className="card-title m-0">
                                    <h3 className="fw-bold m-0">Ad Group Name</h3>
                                </div>

                                <div className="card-toolbar"></div>
                            </div>

                            <div className="card-body border-top border-top-dashed pb-2 pt-6">
                                <div className="row mb-0 ">
                                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                        Ad Group Name
                                    </label>

                                    <div className="col-lg-7 fv-row">
                                        <Input
                                            placeholder="Enter Ad Group Name"
                                            size="large"
                                            style={{ width: "300px" }}
                                            value={adgroup_input}
                                            disabled={all_data?.ad_group?.length > 0}
                                            onPressEnter={(e) => {
                                                if (e.target.value) {
                                                    const isExistAdgoup = all_data?.ad_group?.filter(
                                                        (f) => f === e.target.value
                                                    );
                                                    console.log(isExistAdgoup, "-isExistAdgoup");
                                                    if (all_data?.ad_group?.length > 0) {
                                                        DisplayNotification(
                                                            "info",
                                                            "Info",
                                                            "Ad group already exist"
                                                        );
                                                    } else {
                                                        const newData = [
                                                            ...all_data?.ad_group,
                                                            e.target.value,
                                                        ];
                                                        set_all_data({
                                                            ...all_data,
                                                            ad_group: newData,
                                                        });
                                                        setTimeout(() => {
                                                            set_adgroup_input("");
                                                        }, 600);
                                                    }
                                                } else {
                                                    DisplayNotification(
                                                        "error",
                                                        "Error",
                                                        "Please add ad group"
                                                    );
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value) {
                                                    const isExistAdgoup = all_data?.ad_group?.filter(
                                                        (f) => f === e.target.value
                                                    );

                                                    if (all_data?.ad_group?.length > 0) {
                                                        DisplayNotification(
                                                            "info",
                                                            "Info",
                                                            "Ad group already exist"
                                                        );
                                                    } else {
                                                        const newData = [
                                                            ...all_data?.ad_group,
                                                            e.target.value,
                                                        ];
                                                        set_all_data({
                                                            ...all_data,
                                                            ad_group: newData,
                                                        });
                                                        setTimeout(() => {
                                                            set_adgroup_input("");
                                                        }, 600);
                                                    }
                                                } else {
                                                    DisplayNotification(
                                                        "error",
                                                        "Error",
                                                        "Please add ad group"
                                                    );
                                                }
                                            }}
                                            onChange={(e) => {
                                                set_adgroup_input(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-1 fv-row">
                                        <span className="btn btn-icon btn-primary btn-outline w-40px h-40px position-relative border-0 cursor-pointer">
                                            <i className="ki-outline ki-plus fs-2" />
                                        </span>
                                    </div>
                                </div>
                                {all_data?.ad_group?.length > 0 && (
                                    <>
                                        <div className="separator border-gray-200 my-5" />
                                        <div className="row">
                                            <label className="col-form-label fw-bold fs-5">
                                                Ad Group List
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div className="d-flex flex-wrap">
                                                {all_data?.ad_group?.map((d, i) => {
                                                    return (
                                                        <div>
                                                            <span
                                                                className="d-flex align-items-center my-1 mx-1"
                                                                style={{
                                                                    border: "1px solid #adc6ff",
                                                                    color: "#035091",
                                                                    padding: "1px 8px",
                                                                    borderRadius: "5px",
                                                                    background: "#f0f5ff",
                                                                }}
                                                            >
                                                                <span className="me-2"> {d}</span>
                                                                <i
                                                                    className="ki-outline ki-cross-square text-danger fs-4 cursor-pointer"
                                                                    style={{ marginTop: "1px" }}
                                                                    onClick={() => {
                                                                        const removeAdGroup =
                                                                            all_data?.ad_group?.filter(
                                                                                (f, index) => index !== i
                                                                            );

                                                                        set_all_data({
                                                                            ...all_data,
                                                                            ad_group: removeAdGroup,
                                                                        });
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                            <div className="card-header border-0 cursor-pointer">
                                <div className="card-title m-0">
                                    <h3 className="fw-bold m-0">Optimization Strategy</h3>
                                </div>

                                <div className="card-toolbar"></div>
                            </div>

                            <div className="card-body border-top border-top-dashed pb-2 pt-6">
                                <div className="d-flex w-100 mb-7">
                                    <Dropdown
                                        trigger={["hover"]}
                                        placement="bottom"
                                        getPopupContainer={(triggerNode) =>
                                            triggerNode.parentNode
                                        }
                                        dropdownRender={() => (
                                            <div style={{}}>
                                                <div>
                                                    <div className="separator border-gray-200" />
                                                    <div
                                                        style={{
                                                            minHeight: "230px",
                                                            minWidth: "150px",
                                                            border: "1px solid #fff",
                                                            borderRadius: "7px",
                                                            overflow: "auto",
                                                            background: "#fff",
                                                            boxShadow: "#cccccc 0px 0px 12px 2px",
                                                        }}
                                                    >
                                                        {optimizationStrategy?.map((d) => {
                                                            return (
                                                                <div
                                                                    className=""
                                                                    onClick={() => {
                                                                        set_optimization_strategy_label(
                                                                            d?.display_label
                                                                        );
                                                                        updateOptimizationStrategy(
                                                                            "optimization_strategy",
                                                                            d?.key
                                                                        );
                                                                        set_all_data({
                                                                            ...all_data,
                                                                            optimization_strategy: d?.key,
                                                                            cost_per_click: d?.key === 'REACH' ? '3' : d?.key === 'PAGE_VISITS' ? '1' : '15',
                                                                        })
                                                                    }}
                                                                >
                                                                    <div style={{ padding: "10px" }}>
                                                                        <div className="d-flex align-items-center w-100">
                                                                            <div className="d-flex flex-column w-100">
                                                                                <span
                                                                                    className={`fs-6 mb-2 fw-bold lh-1 ${d?.key ===
                                                                                        all_data?.optimization_strategy
                                                                                        ? "text-primary"
                                                                                        : ""
                                                                                        }`}
                                                                                >
                                                                                    {d?.display_label?.header}
                                                                                </span>
                                                                                <span className="text-gray-600 fs-8 w-75">
                                                                                    {d?.display_label?.content}
                                                                                </span>
                                                                                <span className="text-gray-600 fs-8 w-75">
                                                                                    {d?.display_label?.description}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div></div> */}
                                                                    </div>
                                                                    <div className="separator border-gray-200" />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        <div
                                            className="border rounded w-100 border-dashed border-primary"
                                            id=""
                                        >
                                            <div style={{ padding: "10px" }}>
                                                <div className="d-flex align-items-center w-100">
                                                    <div className="d-flex flex-column w-100">
                                                        <span className="fs-6 mb-2 fw-bold lh-1">
                                                            {optimization_strategy_label?.header}
                                                        </span>
                                                        <span className="text-gray-600 fs-8 w-75">
                                                            {optimization_strategy_label?.content}
                                                        </span>
                                                        <span className="text-gray-600 fs-8 w-75">
                                                            {optimization_strategy_label?.description}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div></div> */}
                                            </div>
                                        </div>
                                    </Dropdown>
                                </div>
                                <div>
                                    <h5 className="mb-4">Cost Control</h5>
                                    <div className="alert alert-dismissible bg-light-success border border-success border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                                        <i className="ki-duotone ki-message-text-2 fs-2hx text-success me-4 mb-5 mb-sm-0"><span className="path1" /><span className="path2" /><span className="path3" /></i>
                                        <div className="d-flex flex-column pe-0 pe-sm-10">
                                            <h5 className="mb-1">Now you can specify a
                                                desired value for a success metric</h5>
                                            <span>Tell us the value you want for a
                                                primary metric, and we'll bid to help
                                                you achieve it.</span>
                                        </div>
                                        <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                                            <i className="ki-outline ki-cross fs-1 text-success" />
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <div className="form-check d-flex">
                                        {/* <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" defaultChecked /> */}
                                        <Checkbox
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    set_all_data({
                                                        ...all_data,
                                                        keep_sucess_metrics: e.target.checked,
                                                    });
                                                } else {
                                                    set_all_data({
                                                        ...all_data,
                                                        keep_sucess_metrics: e.target.checked,
                                                    });
                                                }
                                            }}
                                            checked={all_data?.keep_sucess_metrics}
                                            className="me-4"
                                        />
                                        <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                            <div>
                                                <h5>Keep success metric at or below a desired value</h5>
                                                <div>
                                                    {all_data?.optimization_strategy !== "CONVERSIONS" && `We'll set your bids and add targets while your campaign is running to try to stay at or below a metric value you specify. It can take 72 hours to achieve the desired result.`}
                                                    {all_data?.optimization_strategy === "CONVERSIONS" && `We'll set your bids and add targets while your campaign is running to try to stay at or below a metric value you specify. It can take 14 days to achieve the desired result.`}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="row mb-0 ">
                                    <div className="col-lg-12">
                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4">
                                            <tbody>
                                                <tr>
                                                    <td className="min-w-200px">
                                                        <label className="form-check-label text-gray-800 fs-6 fw-bold" htmlFor>
                                                            Cost per click
                                                        </label>
                                                    </td>
                                                    <td className="min-w-200px  text-center">
                                                        <div className="w-100px mx-auto">
                                                            <div className="input-group input-group-sm  ">
                                                                <span className="input-group-text bg-white fw-bolder fs-5" id="basic-addon1">$</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control fs-7 border-start-0"
                                                                    placeholder
                                                                    value={all_data?.cost_per_click}
                                                                    onChange={(e) => {
                                                                        set_all_data({ ...all_data, cost_per_click: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-7 text-gray-600">
                                                            {all_data?.optimization_strategy === "REACH" && `A value of ${all_data?.cost_per_click} or higher may lead to more viewable impressions.`}
                                                            {all_data?.optimization_strategy === "PAGE_VISITS" && `A value of ${all_data?.cost_per_click} or higher may lead to more page visits.`}
                                                            {all_data?.optimization_strategy === "CONVERSIONS" && `A higher value may lead to more conversions. But we recommend not entering a value higher than the price of the products you want to advertise. Minimum cost per order is ${all_data?.cost_per_click}.`}

                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdGroupWrapper>
    );
}
export default SDAdgroup;