import { GetLinks } from "../../../../core";

export default [
  {
    key: "/day-parting",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-abstract-26 fs-1"></i>
      </span>
    ),
    label: GetLinks("/day-parting", "Day Parting"),
  },
];
