import React, { useState } from "react";
import { DisplayNotification } from "../../../../../../config";
import { Input, Radio, Select, Switch } from "antd";
import { AdGroupWrapper } from "./style";

const SBAdgroup = (props) => {
  const { all_data, set_all_data } = props;
  const [adgroup_input, set_adgroup_input] = useState("");
  return (
    <AdGroupWrapper>
      <div className="flex-column current" style={{ minHeight: 500 }}>
        <div className="row gy-5 g-xl-5">
          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Ad Group Name</h3>
                </div>

                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="row mb-0 ">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    Ad Group Name
                  </label>

                  <div className="col-lg-7 fv-row">
                    <Input
                      placeholder="Enter Ad Group Name"
                      size="large"
                      style={{ width: "300px" }}
                      value={adgroup_input}
                      disabled={all_data?.ad_group?.length > 0}
                      onPressEnter={(e) => {
                        if (e.target.value) {
                          const isExistAdgoup = all_data?.ad_group?.filter(
                            (f) => f === e.target.value
                          );
                          console.log(isExistAdgoup, "-isExistAdgoup");
                          if (all_data?.ad_group?.length > 0) {
                            DisplayNotification(
                              "info",
                              "Info",
                              "Ad group already exist"
                            );
                          } else {
                            const newData = [
                              ...all_data?.ad_group,
                              e.target.value,
                            ];
                            set_all_data({
                              ...all_data,
                              ad_group: newData,
                            });
                            setTimeout(() => {
                              set_adgroup_input("");
                            }, 600);
                          }
                        } else {
                          DisplayNotification(
                            "error",
                            "Error",
                            "Please add ad group"
                          );
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value) {
                          const isExistAdgoup = all_data?.ad_group?.filter(
                            (f) => f === e.target.value
                          );

                          if (all_data?.ad_group?.length > 0) {
                            DisplayNotification(
                              "info",
                              "Info",
                              "Ad group already exist"
                            );
                          } else {
                            const newData = [
                              ...all_data?.ad_group,
                              e.target.value,
                            ];
                            set_all_data({
                              ...all_data,
                              ad_group: newData,
                            });
                            setTimeout(() => {
                              set_adgroup_input("");
                            }, 600);
                          }
                        } else {
                          DisplayNotification(
                            "error",
                            "Error",
                            "Please add ad group"
                          );
                        }
                      }}
                      onChange={(e) => {
                        set_adgroup_input(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-1 fv-row">
                    <span className="btn btn-icon btn-primary btn-outline w-40px h-40px position-relative border-0 cursor-pointer">
                      <i className="ki-outline ki-plus fs-2" />
                    </span>
                  </div>
                </div>
                {all_data?.ad_group?.length > 0 && (
                  <>
                    <div className="separator border-gray-200 my-5" />
                    <div className="row">
                      <label className="col-form-label fw-bold fs-5">
                        Ad Group List
                      </label>
                    </div>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        {all_data?.ad_group?.map((d, i) => {
                          return (
                            <div>
                              <span
                                className="d-flex align-items-center my-1 mx-1"
                                style={{
                                  border: "1px solid #adc6ff",
                                  color: "#035091",
                                  padding: "1px 8px",
                                  borderRadius: "5px",
                                  background: "#f0f5ff",
                                }}
                              >
                                <span className="me-2"> {d}</span>
                                <i
                                  className="ki-outline ki-cross-square text-danger fs-4 cursor-pointer"
                                  style={{ marginTop: "1px" }}
                                  onClick={() => {
                                    const removeAdGroup =
                                      all_data?.ad_group?.filter(
                                        (f, index) => index !== i
                                      );

                                    set_all_data({
                                      ...all_data,
                                      ad_group: removeAdGroup,
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Goals</h3>
                </div>

                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div>
                  <div className="alert alert-dismissible bg-light-success border border-success border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5">
                    <i className="ki-duotone ki-message-text-2 fs-2hx text-success me-4 mb-5 mb-sm-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                    <div className="d-flex flex-column pe-0 pe-sm-10">
                      <h5 className="mb-1">
                        Create a campaign to reach an advertising goal
                      </h5>
                      <span>
                        We'll help you set up a campaign to either drive page
                        visits or increase your brand impression share.
                      </span>
                    </div>
                    <button
                      type="button"
                      className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                      data-bs-dismiss="alert"
                    >
                      <i className="ki-outline ki-cross fs-1 text-success" />
                    </button>
                  </div>
                </div>
                <div className="mb-7">
                  <p className="mb-0 fs-6">
                    Choose a campaign goal that aligns with your business
                    objective. We'll make bidding and targeting recommendations
                    to help achieve this goal.
                  </p>
                </div>
                <div className="mb-1 row" data-kt-buttons="true">
                  <Radio.Group
                    name="radiogroup"
                    onChange={(e) => {
                      set_all_data({
                        ...all_data,
                        campaign_goal: e.target.value,
                      });
                    }}
                    value={all_data?.campaign_goal}
                    style={{ width: "100%" }}
                  >
                    <Radio
                      value={"drive_page_visits"}
                      style={{ width: "100%" }}
                    >
                      <div className="col-md-12">
                        <label className="btn btn-outline btn-outline-dashed btn-active-light d-flex flex-stack text-start p-6 mb-0">
                          <div className="d-flex align-items-center me-2">
                            <div className="flex-grow-1">
                              <h2 className="d-flex align-items-center fs-4 fw-bold flex-wrap">
                                Drive page visits
                              </h2>
                              <div className="opacity-75 fs-7">
                                Drive traffic to your landing page and details
                                page.
                                <br />
                                Success metric: Clicks
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </Radio>
                    <Radio
                      value={"grow_brand_impression_share"}
                      style={{ width: "100%" }}
                    >
                      <div className="col-md-12">
                        <label className="btn btn-outline btn-outline-dashed btn-active-light d-flex flex-stack text-start p-6 mb-0">
                          <div className="d-flex align-items-center me-2">
                            <div className="flex-grow-1">
                              <h2 className="d-flex align-items-center fs-4 fw-bold flex-wrap">
                                Grow brand impression share
                              </h2>
                              <div className="opacity-75 fs-7">
                                Show your ads in the top-of-search placement to
                                shoppers who search for your brand or within
                                your brand categories..
                                <br />
                                Cost Type: Cost per 1000 viewable impressions
                                (VCPM).
                                <br />
                                Success metric: Top of search impression share
                                (IS)
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Bidding</h3>
                </div>
                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div>
                  <h4 className="mb-1">Automated Bidding</h4>
                </div>
                <div className="mt-7 row">
                  <div className="col-md-12">
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <Switch
                        value={all_data?.is_automate_bidding}
                        onChange={(e) => {
                          set_all_data({ ...all_data, is_automate_bidding: e });
                        }}
                      />
                      <label className="form-check-label ms-5">
                        <div>
                          <h5 className="mb-1">
                            Allow Amazon to automatically optimize bids for
                            placements other than top of search.
                          </h5>
                          <div>
                            Automatic biddibg or bid adjustments will not be
                            applied to the ads with video ad format
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  {!all_data?.is_automate_bidding && (
                    <div className=" mt-7" id="targetDiv">
                      <div className="separator separator-dashed border-gray-300 mb-7" />
                      <h5 className="mb-3">Set a custom bid adjustment</h5>
                      <div className="row">
                        <div className="col-md-3">
                          <Select
                            style={{ width: "100%" }}
                            value={all_data?.bid_adjusted_by}
                            onChange={(e) => {
                              set_all_data({
                                ...all_data,
                                bid_adjusted_by: e,
                              });
                            }}
                            size="large"
                            options={[
                              { label: "Decrease By", value: "decrease_by" },
                              { label: "Increase By", value: "increase_by" },
                            ]}
                          />
                        </div>
                        <div className="col-md-4">
                          <div className="mx-auto">
                            <Input
                              value={all_data?.bid_adjusted_value}
                              onChange={(e) => {
                                set_all_data({
                                  ...all_data,
                                  bid_adjusted_value: e.target.value,
                                });
                              }}
                              size="large"
                              suffix={
                                <span
                                  className=" bg-white fw-bolder fs-5"
                                  id="basic-addon1"
                                >
                                  %
                                </span>
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <p className="mb-0 mt-3 fw-bold fs-6">
                            For placements other than top of search
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p className="mb-0 mt-3 text-danger fs-7">
                            Example: A{" "}
                            <span className="text-gray-900">40%</span>
                            decrease on a
                            <span className="text-gray-900">$5.00</span> bid
                            will become{" "}
                            <span className="text-gray-900">$3.00</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdGroupWrapper>
  );
};

export default SBAdgroup;
