import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Input, Popover, Select, Skeleton, Upload } from "antd";
import React from "react";
import Pagination from "../../../../../../component/esf-table/lib/pagination";
import NoData from "../../../../../../component/no-data";
import { downloadFile } from "../../../../../../config";
const { Dragger } = Upload;
const Step5ManualProductTargeting = (props) => {
  const {
    all_common_hide_show,
    set_all_common_hide_show,
    bid_selection,
    set_bid_selection,
    common_default_bid,
    set_common_default_bid,
    category_targeting_loading,
    sugg_category_targeting,
    selected_categroy_list,
    set_selected_categroy_list,
    selected_individual_product_filter_by,
    set_selected_individual_product_filter_by,
    product_targeting_loading,
    sugg_product_targeting,
    selected_individual_product_list,
    set_selected_individual_product_list,
    all_filters,
    totalPage,
    onPerPage,
    onPageNo,
    enterlist_product,
    set_enterlist_product,
    CheckProductTargetingByAsin,
    handleUploadProductsFile,
    isUploading,
    selected_currency_sign,
    selected_marketplace,
  } = props;
  return (
    <>
      <div className="manualDiv1">
        <div className="row gy-5 g-xl-5">
          <div className="col-md-12">
            <ul className="nav nav-pills mt-5  fs-6 border-0">
              <li
                className="nav-item"
                onClick={() => {
                  set_all_common_hide_show({
                    ...all_common_hide_show,
                    manual_product_targeting: "category",
                  });
                }}
              >
                <span
                  className={`nav-link fw-bold btn-sm btn bg-white btn-active-primary btn-active-color-light fs-7 ${
                    all_common_hide_show?.manual_product_targeting ===
                    "category"
                      ? "active"
                      : ""
                  }`}
                >
                  Categories
                </span>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  set_all_common_hide_show({
                    ...all_common_hide_show,
                    manual_product_targeting: "individual_products",
                  });
                }}
              >
                <span
                  className={`nav-link fw-bold btn-sm btn bg-white btn-active-primary btn-active-color-light fs-7 ${
                    all_common_hide_show?.manual_product_targeting ===
                    "individual_products"
                      ? "active"
                      : ""
                  }`}
                >
                  Individual Products
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="tab-content" id="myTabContent">
              <div
                className={`tab-pane fade ${
                  all_common_hide_show?.manual_product_targeting === "category"
                    ? " show active"
                    : ""
                }`}
              >
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-6">
                    <div className="card min-h-450px">
                      <div className="card-header card-header-stretch">
                        <div className="card-title fs-5 m-0">
                          <h3 className="fw-bold m-0">Product Targeting</h3>
                        </div>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-2">
                        <ul className="nav nav-tabs mb-5 nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                          <li
                            className={`nav-item cursor-pointer `}
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                manual_product_category: "suggested",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.manual_product_category ===
                                "suggested"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Suggested
                            </span>
                          </li>
                          {/* <li
                                className={`nav-item cursor-pointer`}
                                onClick={() => {
                                  set_all_common_hide_show({
                                    ...all_common_hide_show,
                                    manual_product_category: "search",
                                  });
                                }}
                              >
                                <span
                                  className={`nav-link fw-bold text-gray-800 ${
                                    all_common_hide_show?.manual_product_category ===
                                    "search"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  Search
                                </span>
                              </li> */}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className={`tab-pane fade ${
                              all_common_hide_show?.manual_product_category ===
                              "suggested"
                                ? "show active"
                                : ""
                            }`}
                          >
                            <div className="mt-0 ">
                              <div className="row align-items-center">
                                <div className="col-md-3">
                                  <span className="fw-bolder fs-6">Bid</span>
                                </div>
                                <div className="col-md-9">
                                  <div className="d-flex">
                                    <Select
                                      style={{ width: "200px" }}
                                      size="medium"
                                      value={bid_selection}
                                      onChange={(e) => {
                                        set_bid_selection(e);
                                      }}
                                      options={[
                                        {
                                          label: "Suggested Bid",
                                          value: "suggested_bid",
                                        },
                                        {
                                          label: "Custom Bid",
                                          value: "custom_bid",
                                        },
                                        {
                                          label: "Default Bid",
                                          value: "default_bid",
                                        },
                                      ]}
                                      className="me-2"
                                    />
                                    {bid_selection !== "suggested_bid" && (
                                      <Input
                                        size="medium"
                                        prefix={selected_currency_sign}
                                        style={{ width: "200px" }}
                                        value={common_default_bid}
                                        onChange={(e) => {
                                          set_common_default_bid(
                                            e.target.value
                                          );
                                        }}
                                        placeholder={`${
                                          bid_selection === "suggested_bid"
                                            ? "Suggested Bid"
                                            : bid_selection === "custom_bid"
                                            ? "Custom Bid"
                                            : "Default Bid"
                                        }`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 ">
                              <div className="table-responsive ">
                                {category_targeting_loading ? (
                                  <Skeleton active block />
                                ) : sugg_category_targeting?.length > 0 ? (
                                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                    <thead>
                                      <tr className="fw-bolder fs-7">
                                        <th>Suggestion</th>
                                        <th>Sugg. Bid</th>
                                        <th className="text-end">
                                          <span>Add All</span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {sugg_category_targeting?.map((d) => {
                                        const isAddCategory =
                                          selected_categroy_list?.filter(
                                            (f) =>
                                              f?.category_id === d?.category_id
                                          );
                                        return (
                                          <tr className="bg-light fs-7">
                                            <td>
                                              <div>
                                                {/* <b>Category:</b>{" "} */}
                                                {d?.category_name}
                                              </div>
                                            </td>
                                            <td>
                                              {selected_currency_sign}
                                              {d?.suggested_bid}
                                            </td>
                                            <td className="text-end">
                                              {isAddCategory?.length == 0 && (
                                                <span
                                                  className="fw-bold text-primary"
                                                  onClick={() => {
                                                    const newObj = {
                                                      category_id:
                                                        d?.category_id,
                                                      suggested_bid:
                                                        d?.suggested_bid,
                                                      new_suggested_bid:
                                                        bid_selection ===
                                                        "suggested_bid"
                                                          ? d?.suggested_bid
                                                          : common_default_bid,
                                                      category_name:
                                                        d?.category_name,
                                                    };

                                                    set_selected_categroy_list(
                                                      (prev) => {
                                                        return [
                                                          ...prev,
                                                          newObj,
                                                        ];
                                                      }
                                                    );
                                                  }}
                                                >
                                                  Add
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  <NoData type="new" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`tab-pane fade ${
                              all_common_hide_show?.manual_product_category ===
                              "search"
                                ? "show active"
                                : ""
                            }`}
                          >
                            <div className="row align-items-center">
                              <div className="col-md-12">
                                <div className="w-100 position-relative mb-2 mb-lg-0">
                                  <Input
                                    prefix={
                                      <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                                    }
                                    placeholder="Search by product name or categories"
                                    size="large"
                                    // value={all_filters?.search}
                                    onChange={(e) => {}}
                                    onPressEnter={(e) => {}}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-6">
                    <div className="card min-h-450px">
                      <div className="card-header ">
                        <div className="card-title m-0">
                          <h3 className="fw-bold m-0">Product Targeting</h3>
                        </div>
                        <div className="card-toolbar">
                          <span className="btn fs-7 btn-light-primary fw-bold btn-sm">
                            Remove All
                          </span>
                        </div>
                      </div>
                      <div className="card-body p-5">
                        <div className="table-responsive ">
                          {category_targeting_loading ? (
                            <Skeleton active block />
                          ) : selected_categroy_list?.length > 0 ? (
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                              <thead>
                                <tr className="fw-bolder fs-7">
                                  <th>Suggestion</th>
                                  <th>Sugg. Bid</th>
                                  <th className="text-end">
                                    <span></span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {selected_categroy_list?.map((d) => {
                                  return (
                                    <tr className="bg-light fs-7">
                                      <td>
                                        <div>
                                          {/* <b>Category:</b>{" "} */}
                                          {d?.category_name}
                                        </div>
                                      </td>
                                      <td>
                                        {selected_currency_sign}
                                        {d?.suggested_bid} <br />
                                        <Input
                                          className="mt-1"
                                          size="small"
                                          style={{ width: "100px" }}
                                          value={d?.new_suggested_bid}
                                          onChange={(e) => {
                                            set_selected_categroy_list(
                                              (prev) => {
                                                const existing_cat = prev?.find(
                                                  (es) =>
                                                    es?.category_id ===
                                                    d?.category_id
                                                );
                                                if (existing_cat) {
                                                  return prev.map((sc) =>
                                                    sc?.category_id ===
                                                    d?.category_id
                                                      ? {
                                                          ...sc,
                                                          new_suggested_bid:
                                                            e.target.value,
                                                        }
                                                      : sc
                                                  );
                                                } else {
                                                  return [...prev];
                                                }
                                              }
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="justify-content-end d-flex">
                                        <span
                                          className="ms-2 btn btn-icon h-35px w-35px btn-light"
                                          onClick={() => {
                                            const afterDeletingCat =
                                              selected_categroy_list?.filter(
                                                (cl) =>
                                                  cl?.category_id !==
                                                  d?.category_id
                                              );
                                            set_selected_categroy_list(
                                              afterDeletingCat
                                            );
                                          }}
                                        >
                                          <i className="ki-outline ki-cross-square text-primary fs-2" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <NoData type="new" height="340px" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  all_common_hide_show?.manual_product_targeting ===
                  "individual_products"
                    ? " show active"
                    : ""
                }`}
              >
                <div className="row gy-5 g-xl-5">
                  <div className="col-xxl-6">
                    <div className="card min-h-450px">
                      <div className="card-header card-header-stretch">
                        <div className="card-title fs-5 m-0">
                          <h3 className="fw-bold m-0">
                            Individual Product Targeting
                          </h3>
                        </div>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-2">
                        <ul className="nav nav-tabs mb-5 nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                          <li
                            className="nav-item"
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                manual_product_individual: "suggested",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.manual_product_individual ===
                                "suggested"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              Suggested
                            </span>
                          </li>
                          {/* <li
                                className="nav-item"
                                onClick={() => {
                                  set_all_common_hide_show({
                                    ...all_common_hide_show,
                                    manual_product_individual: "search",
                                  });
                                }}
                              >
                                <span
                                  className={`nav-link fw-bold text-gray-800 ${
                                    all_common_hide_show?.manual_product_individual ===
                                    "search"
                                      ? "active"
                                      : ""
                                  } `}
                                >
                                  Search
                                </span>
                              </li> */}
                          <li
                            className="nav-item"
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                manual_product_individual: "enter_list",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.manual_product_individual ===
                                "enter_list"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              Enter List
                            </span>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => {
                              set_all_common_hide_show({
                                ...all_common_hide_show,
                                manual_product_individual: "upload",
                              });
                            }}
                          >
                            <span
                              className={`nav-link fw-bold text-gray-800 ${
                                all_common_hide_show?.manual_product_individual ===
                                "upload"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              Upload
                            </span>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className={`tab-pane fade ${
                              all_common_hide_show?.manual_product_individual ===
                              "suggested"
                                ? "show active "
                                : ""
                            }`}
                          >
                            <div className="mt-0 ">
                              <div className="row align-items-center">
                                <div className="col-md-3">
                                  <span className="fw-bolder fs-6">Bid</span>
                                </div>
                                <div className="col-md-9">
                                  <div className="d-flex">
                                    <Select
                                      style={{ width: "200px" }}
                                      size="medium"
                                      value={bid_selection}
                                      onChange={(e) => {
                                        set_bid_selection(e);
                                      }}
                                      options={[
                                        {
                                          label: "Suggested Bid",
                                          value: "suggested_bid",
                                        },
                                        {
                                          label: "Custom Bid",
                                          value: "custom_bid",
                                        },
                                        {
                                          label: "Default Bid",
                                          value: "default_bid",
                                        },
                                      ]}
                                      className="me-2"
                                    />
                                    {bid_selection !== "suggested_bid" && (
                                      <Input
                                        size="medium"
                                        prefix={selected_currency_sign}
                                        style={{ width: "200px" }}
                                        value={common_default_bid}
                                        onChange={(e) => {
                                          set_common_default_bid(
                                            e.target.value
                                          );
                                        }}
                                        placeholder={`${
                                          bid_selection === "suggested_bid"
                                            ? "Suggested Bid"
                                            : bid_selection === "custom_bid"
                                            ? "Custom Bid"
                                            : "Default Bid"
                                        }`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-center my-7">
                              <div className="col-md-3">
                                <span className="fw-bolder fs-6">
                                  Filter by
                                </span>
                              </div>
                              <div className="col-md-9">
                                <div className=" d-flex gap-5 mt-0">
                                  <Checkbox.Group
                                    options={["Exact", "Expanded"]}
                                    value={
                                      selected_individual_product_filter_by
                                    }
                                    onChange={(e) => {
                                      e?.length !== 0 &&
                                        set_selected_individual_product_filter_by(
                                          e
                                        );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-center my-7">
                              <div
                                className="table-responsive "
                                style={{
                                  height: "400px",
                                  overflow: "scroll",
                                }}
                              >
                                {product_targeting_loading ? (
                                  <Skeleton active block />
                                ) : sugg_product_targeting?.length > 0 ? (
                                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                    <thead>
                                      <tr className="fw-bolder fs-7">
                                        <th>ASIN</th>
                                        <th>Sugg. Bid</th>
                                        <th>Type</th>
                                        <th className="text-end">
                                          <span>Action</span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {sugg_product_targeting?.map((d) => {
                                        const isAddProduct =
                                          selected_individual_product_list?.filter(
                                            (f) =>
                                              f.recommendedAsin ===
                                              d.recommendedAsin
                                          );
                                        return (
                                          <tr className="bg-light fs-7">
                                            <td>
                                              <div className="d-flex">
                                                <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                                  <img
                                                    src={d?.image_url}
                                                    className
                                                    alt
                                                  />
                                                </div>
                                                <div className="d-flex flex-column me-2 fs-7">
                                                  <div className="d-flex">
                                                    <Popover
                                                      content={
                                                        <div
                                                          style={{
                                                            height:
                                                              "max-content",
                                                            maxWidth: "400px",
                                                          }}
                                                        >
                                                          {d?.product_name}
                                                        </div>
                                                      }
                                                      placement="bottom"
                                                    >
                                                      <div
                                                        className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7"
                                                        style={{
                                                          maxWidth: "200px",
                                                        }}
                                                      >
                                                        {d?.product_name}
                                                      </div>
                                                    </Popover>
                                                  </div>
                                                  <span className="text-gray-700 fs-8">
                                                    ASIN:{" "}
                                                    <b className="text-primary">
                                                      {d?.recommendedAsin}
                                                    </b>{" "}
                                                  </span>
                                                  <span className="text-gray-700 fs-8">
                                                    Price:{" "}
                                                    <b className="">
                                                      {selected_currency_sign}
                                                      {d?.item_price}
                                                    </b>{" "}
                                                  </span>
                                                </div>
                                                {/* <b>Category:</b>{" "} */}
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                {/* <b>Category:</b>{" "} */}
                                                {selected_currency_sign}
                                                {d?.suggested_bid}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="fw-bold">
                                                {" "}
                                                {selected_individual_product_filter_by?.includes(
                                                  "Exact"
                                                ) && "Exact"}
                                              </div>
                                              <div className="fw-bold">
                                                {" "}
                                                {selected_individual_product_filter_by?.includes(
                                                  "Expanded"
                                                ) && "Expanded"}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="fw-bold mb-1">
                                                {" "}
                                                {selected_individual_product_filter_by?.includes(
                                                  "Exact"
                                                ) &&
                                                !isAddProduct?.[0]?.exact ? (
                                                  <div className="justify-content-end d-flex align-items-center">
                                                    {" "}
                                                    <span
                                                      className="fw-bold text-primary ms-2 cursor-pointer"
                                                      onClick={() => {
                                                        const newObj = {
                                                          recommendedAsin:
                                                            d?.recommendedAsin,
                                                          suggested_bid:
                                                            d?.suggested_bid,
                                                          image_url:
                                                            d?.image_url,
                                                          product_name:
                                                            d?.product_name,
                                                          item_price:
                                                            d?.item_price,
                                                          exact: {
                                                            suggested_bid:
                                                              bid_selection ===
                                                              "suggested_bid"
                                                                ? d?.suggested_bid
                                                                : common_default_bid,
                                                            state: "ENABLED",
                                                            expression_type:
                                                              "ASIN_SAME_AS",
                                                            expression_value:
                                                              d?.recommendedAsin,
                                                          },
                                                        };

                                                        set_selected_individual_product_list(
                                                          (prev) => {
                                                            const existingASIN =
                                                              prev?.find(
                                                                (es) =>
                                                                  es?.recommendedAsin ===
                                                                  d?.recommendedAsin
                                                              );
                                                            if (existingASIN) {
                                                              return prev.map(
                                                                (sk) =>
                                                                  sk?.recommendedAsin ===
                                                                  d?.recommendedAsin
                                                                    ? {
                                                                        ...sk,
                                                                        ...newObj,
                                                                      }
                                                                    : sk
                                                              );
                                                            } else {
                                                              return [
                                                                ...prev,
                                                                newObj,
                                                              ];
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      Add
                                                    </span>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div className="fw-bold">
                                                {selected_individual_product_filter_by?.includes(
                                                  "Expanded"
                                                ) &&
                                                !isAddProduct?.[0]?.expanded ? (
                                                  <div className="justify-content-end d-flex align-items-center">
                                                    {" "}
                                                    <span
                                                      className="fw-bold text-primary ms-2 cursor-pointer"
                                                      onClick={() => {
                                                        const newObj = {
                                                          recommendedAsin:
                                                            d?.recommendedAsin,
                                                          suggested_bid:
                                                            d?.suggested_bid,
                                                          image_url:
                                                            d?.image_url,
                                                          product_name:
                                                            d?.product_name,
                                                          item_price:
                                                            d?.item_price,
                                                          expanded: {
                                                            suggested_bid:
                                                              bid_selection ===
                                                              "suggested_bid"
                                                                ? d?.suggested_bid
                                                                : common_default_bid,
                                                            state: "ENABLED",
                                                            expression_type:
                                                              "ASIN_EXPANDED_FROM",
                                                            expression_value:
                                                              d?.recommendedAsin,
                                                          },
                                                        };

                                                        set_selected_individual_product_list(
                                                          (prev) => {
                                                            const existingASIN =
                                                              prev?.find(
                                                                (es) =>
                                                                  es?.recommendedAsin ===
                                                                  d?.recommendedAsin
                                                              );
                                                            if (existingASIN) {
                                                              return prev.map(
                                                                (sk) =>
                                                                  sk?.recommendedAsin ===
                                                                  d?.recommendedAsin
                                                                    ? {
                                                                        ...sk,
                                                                        ...newObj,
                                                                      }
                                                                    : sk
                                                              );
                                                            } else {
                                                              return [
                                                                ...prev,
                                                                newObj,
                                                              ];
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      Add
                                                    </span>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  <NoData type="new" height="350px" />
                                )}
                              </div>
                              <Pagination
                                loading={
                                  product_targeting_loading ||
                                  sugg_product_targeting?.length === 0
                                }
                                pageSize={all_filters?.["per-page"]}
                                page={all_filters?.page}
                                totalPage={totalPage}
                                onPerPage={onPerPage}
                                onPageNo={onPageNo}
                              />
                            </div>
                          </div>
                          {/* <div
                                className={`tab-pane fade ${
                                  all_common_hide_show?.manual_product_individual ===
                                  "search"
                                    ? "show active "
                                    : ""
                                }`}
                              >
                                <div className="mt-0 ">
                                  <div className="row align-items-center">
                                    <div className="col-md-3">
                                      <span className="fw-bolder fs-6">
                                        Bid
                                      </span>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="d-flex w-100">
                                        <Select
                                          style={{ width: "100%" }}
                                          size="medium"
                                          defaultValue="suggested_bid"
                                          options={[
                                            {
                                              label: "Suggested Bid",
                                              value: "suggested_bid",
                                            },
                                            {
                                              label: "Custom Bid",
                                              value: "custom_bid",
                                            },
                                            {
                                              label: "Default Bid",
                                              value: "default_bid",
                                            },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center my-7">
                                  <div className="col-md-3">
                                    <span className="fw-bolder fs-6">
                                      Filter by
                                    </span>
                                  </div>
                                  <div className="col-md-9">
                                    <div className=" d-flex gap-5 mt-0">
                                      <div className="form-check ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue
                                          id="flexCheckDefault"
                                          defaultChecked
                                        />
                                        <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                          Exact
                                        </label>
                                      </div>
                                      <div className="form-check ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue
                                          id="flexCheckDefault1"
                                        />
                                        <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                          Expanded
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-md-12">
                                    <div
                                      className="w-100 position-relative mb-2 mb-lg-0"
                                      autoComplete="off"
                                    >
                                      <Input
                                        prefix={
                                          <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                                        }
                                        placeholder="Search by product name or categories"
                                        size="large"
                                        // value={all_filters?.search}
                                        onChange={(e) => {}}
                                        onPressEnter={(e) => {}}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                          <div
                            className={`tab-pane fade ${
                              all_common_hide_show?.manual_product_individual ===
                              "enter_list"
                                ? "show active "
                                : ""
                            }`}
                          >
                            <div className="mt-0 ">
                              <div className="row align-items-center">
                                <div className="col-md-3">
                                  <span className="fw-bolder fs-6">Bid</span>
                                </div>
                                <div className="col-md-9">
                                  <div className="d-flex">
                                    <Select
                                      style={{ width: "200px" }}
                                      size="medium"
                                      value={bid_selection}
                                      onChange={(e) => {
                                        set_bid_selection(e);
                                      }}
                                      options={[
                                        {
                                          label: "Suggested Bid",
                                          value: "suggested_bid",
                                        },
                                        {
                                          label: "Custom Bid",
                                          value: "custom_bid",
                                        },
                                        {
                                          label: "Default Bid",
                                          value: "default_bid",
                                        },
                                      ]}
                                      className="me-2"
                                    />
                                    {bid_selection !== "suggested_bid" && (
                                      <Input
                                        size="medium"
                                        prefix={selected_currency_sign}
                                        style={{ width: "200px" }}
                                        value={common_default_bid}
                                        onChange={(e) => {
                                          set_common_default_bid(
                                            e.target.value
                                          );
                                        }}
                                        placeholder={`${
                                          bid_selection === "suggested_bid"
                                            ? "Suggested Bid"
                                            : bid_selection === "custom_bid"
                                            ? "Custom Bid"
                                            : "Default Bid"
                                        }`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-center my-7">
                              <div className="col-md-3">
                                <span className="fw-bolder fs-6">
                                  Filter by
                                </span>
                              </div>
                              <div className="col-md-9">
                                <div className=" d-flex gap-5 mt-0">
                                  <Checkbox.Group
                                    options={["Exact", "Expanded"]}
                                    value={
                                      selected_individual_product_filter_by
                                    }
                                    onChange={(e) => {
                                      e?.length !== 0 &&
                                        set_selected_individual_product_filter_by(
                                          e
                                        );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-5">
                              {/* Individual Product Targeting Enter list */}
                              <Input.TextArea
                                cols={30}
                                rows={10}
                                placeholder="Enter ASINs"
                                value={enterlist_product}
                                onChange={(e) => {
                                  set_enterlist_product(e.target.value);
                                }}
                              />
                              <div className="mt-3 text-end">
                                <button
                                  className="btn btn-dark fs-7"
                                  onClick={() => {
                                    const textboxAsin =
                                      enterlist_product?.split(",");
                                    const data = {
                                      marketplace_id: selected_marketplace,
                                      product_asins: [],
                                      asins: textboxAsin.map((str) =>
                                        str.trim()
                                      ),
                                    };
                                    CheckProductTargetingByAsin(data);
                                    // const isAddProduct =
                                    //   selected_individual_product_list?.filter(
                                    //     (f) =>
                                    //       f.recommendedAsin ===
                                    //       enterlist_product
                                    //   );
                                    // if (
                                    //   selected_individual_product_filter_by?.includes(
                                    //     "Exact"
                                    //   ) &&
                                    //   !isAddProduct?.[0]?.exact
                                    // ) {
                                    //   const newObj = {
                                    //     recommendedAsin: enterlist_product,
                                    //     suggested_bid: common_default_bid,
                                    //     exact: {
                                    //       suggested_bid: common_default_bid,
                                    //       state: "ENABLED",
                                    //       expression_type: "ASIN_SAME_AS",
                                    //       expression_value:
                                    //         enterlist_product,
                                    //     },
                                    //   };

                                    //   set_selected_individual_product_list(
                                    //     (prev) => {
                                    //       const existingASIN = prev?.find(
                                    //         (es) =>
                                    //           es?.recommendedAsin ===
                                    //           enterlist_product
                                    //       );
                                    //       if (existingASIN) {
                                    //         return prev.map((sk) =>
                                    //           sk?.recommendedAsin ===
                                    //           enterlist_product
                                    //             ? {
                                    //                 ...sk,
                                    //                 ...newObj,
                                    //               }
                                    //             : sk
                                    //         );
                                    //       } else {
                                    //         return [...prev, newObj];
                                    //       }
                                    //     }
                                    //   );
                                    // }
                                    // if (
                                    //   selected_individual_product_filter_by?.includes(
                                    //     "Expanded"
                                    //   ) &&
                                    //   !isAddProduct?.[0]?.expanded
                                    // ) {
                                    //   const newObj = {
                                    //     recommendedAsin: enterlist_product,
                                    //     suggested_bid: common_default_bid,
                                    //     expanded: {
                                    //       suggested_bid: common_default_bid,
                                    //       state: "ENABLED",
                                    //       expression_type:
                                    //         "ASIN_EXPANDED_FROM",
                                    //       expression_value:
                                    //         enterlist_product,
                                    //     },
                                    //   };

                                    //   set_selected_individual_product_list(
                                    //     (prev) => {
                                    //       const existingASIN = prev?.find(
                                    //         (es) =>
                                    //           es?.recommendedAsin ===
                                    //           enterlist_product
                                    //       );
                                    //       if (existingASIN) {
                                    //         return prev.map((sk) =>
                                    //           sk?.recommendedAsin ===
                                    //           enterlist_product
                                    //             ? {
                                    //                 ...sk,
                                    //                 ...newObj,
                                    //               }
                                    //             : sk
                                    //         );
                                    //       } else {
                                    //         return [...prev, newObj];
                                    //       }
                                    //     }
                                    //   );
                                    // }
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`tab-pane fade ${
                              all_common_hide_show?.manual_product_individual ===
                              "upload"
                                ? "show active "
                                : ""
                            }`}
                          >
                            <p className="mb-3 mt-2 text-muted">
                              Use the template to make sure your information
                              uploads accurately.
                            </p>
                            <div className="mb-5">
                              <span
                                className="fw-bold text-primary cursor-pointer"
                                onClick={() => {
                                  downloadFile(
                                    "/assets/media/sample-template/",
                                    "product_targeting_template.xlsx"
                                  );
                                }}
                              >
                                <i className="bi bi-download text-primary me-2" />
                                Download the sample template
                              </span>
                            </div>

                            <div className="form">
                              <div className="fv-row">
                                <>
                                  <Dragger
                                    customRequest={({ file, onSuccess }) => {
                                      // Simulate a file upload (do nothing here)
                                      setTimeout(() => {
                                        onSuccess(null, file);
                                      }, 0);
                                    }}
                                    onChange={handleUploadProductsFile}
                                    showUploadList={false}
                                  >
                                    <div className="dz-message needsclick">
                                      {isUploading ? (
                                        <LoadingOutlined
                                          style={{
                                            color: "#008000",
                                            fontSize: "28px",
                                          }}
                                        />
                                      ) : (
                                        <i className="ki-duotone ki-file-up fs-3x text-primary">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      )}

                                      <div className="ms-4">
                                        <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                          Drop files here or click to upload.
                                        </h3>
                                        <span className="fs-7 fw-semibold text-gray-600">
                                          Accepted formats: CSV, XLSX
                                        </span>
                                      </div>
                                    </div>
                                  </Dragger>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-6">
                    <div className="card min-h-450px">
                      <div className="card-header ">
                        <div className="card-title m-0">
                          <h3 className="fw-bold m-0"></h3>
                        </div>
                        <div className="card-toolbar">
                          <span
                            className="btn fs-7 btn-light-primary fw-bold btn-sm"
                            onClick={() => {
                              set_selected_individual_product_list([]);
                            }}
                          >
                            Remove All
                          </span>
                        </div>
                      </div>
                      <div className="card-body p-5">
                        <div className="row align-items-center my-7">
                          <div
                            className="table-responsive "
                            style={{
                              height: "525px",
                              overflow: "scroll",
                            }}
                          >
                            {product_targeting_loading ? (
                              <Skeleton active block />
                            ) : selected_individual_product_list?.length > 0 ? (
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                <thead>
                                  <tr className="fw-bolder fs-7">
                                    <th>ASIN</th>
                                    <th>Sugg. Bid</th>
                                    <th>Type</th>
                                    <th className="text-end">
                                      <span>Action</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selected_individual_product_list?.map(
                                    (d, i) => {
                                      return (
                                        <tr className="bg-light fs-7">
                                          <td>
                                            <div className="d-flex">
                                              <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                                <img
                                                  src={d?.image_url}
                                                  className
                                                  alt
                                                />
                                              </div>
                                              <div className="d-flex flex-column me-2 fs-7">
                                                <div className="d-flex">
                                                  <Popover
                                                    content={
                                                      <div
                                                        style={{
                                                          height: "max-content",
                                                          maxWidth: "400px",
                                                        }}
                                                      >
                                                        {d?.product_name}
                                                      </div>
                                                    }
                                                    placement="bottom"
                                                  >
                                                    <div
                                                      className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7"
                                                      style={{
                                                        maxWidth: "200px",
                                                      }}
                                                    >
                                                      {d?.product_name}
                                                    </div>
                                                  </Popover>
                                                </div>
                                                <span className="text-gray-700 fs-8">
                                                  ASIN:{" "}
                                                  <b className="text-primary">
                                                    {d?.recommendedAsin}
                                                  </b>{" "}
                                                </span>
                                                <span className="text-gray-700 fs-8">
                                                  Price:{" "}
                                                  <b className="">
                                                    {selected_currency_sign}
                                                    {d?.item_price}
                                                  </b>{" "}
                                                </span>
                                              </div>
                                              {/* <b>Category:</b>{" "} */}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="text-start">
                                              {/* <b>Category:</b>{" "} */}
                                              <span className="fw-bold fs-5">
                                                {selected_currency_sign}
                                                {d?.suggested_bid}
                                              </span>
                                              {Object.entries(d?.exact || {})
                                                ?.length > 0 && (
                                                <div className="mb-1">
                                                  <Input
                                                    prefix={
                                                      selected_currency_sign
                                                    }
                                                    size="medium"
                                                    style={{
                                                      width: "100px",
                                                    }}
                                                    placeholder="Exact"
                                                    value={
                                                      d?.exact?.suggested_bid
                                                    }
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      // Input Value

                                                      set_selected_individual_product_list(
                                                        (prev) => {
                                                          return prev?.map(
                                                            (a) => {
                                                              if (
                                                                a?.recommendedAsin ===
                                                                d?.recommendedAsin
                                                              ) {
                                                                return {
                                                                  ...d,
                                                                  exact: {
                                                                    ...d?.exact,
                                                                    suggested_bid:
                                                                      value,
                                                                  },
                                                                };
                                                              } else {
                                                                return a;
                                                              }
                                                            }
                                                          );
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {Object.entries(d?.expanded || {})
                                                ?.length > 0 && (
                                                <div>
                                                  <Input
                                                    prefix={
                                                      selected_currency_sign
                                                    }
                                                    size="medium"
                                                    style={{
                                                      width: "100px",
                                                    }}
                                                    placeholder="Expanded"
                                                    value={
                                                      d?.expanded?.suggested_bid
                                                    }
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      // Input Value

                                                      set_selected_individual_product_list(
                                                        (prev) => {
                                                          return prev?.map(
                                                            (a) => {
                                                              if (
                                                                a?.recommendedAsin ===
                                                                d?.recommendedAsin
                                                              ) {
                                                                return {
                                                                  ...d,
                                                                  expanded: {
                                                                    ...d?.expanded,
                                                                    suggested_bid:
                                                                      value,
                                                                  },
                                                                };
                                                              } else {
                                                                return a;
                                                              }
                                                            }
                                                          );
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="fw-bold mb-2">
                                              {Object.entries(d?.exact || {})
                                                ?.length > 0 && "Exact"}
                                            </div>
                                            <div className="fw-bold">
                                              {Object.entries(d?.expanded || {})
                                                ?.length > 0 && "Expanded"}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="fw-bold mb-1">
                                              {" "}
                                              {Object.entries(d?.exact || {})
                                                ?.length > 0 && (
                                                <div className="justify-content-end d-flex align-items-center">
                                                  {" "}
                                                  <span
                                                    className="ms-2 btn btn-icon h-35px w-35px btn-light"
                                                    onClick={() => {
                                                      const after_deleted_list =
                                                        selected_individual_product_list.map(
                                                          (item) => {
                                                            if (
                                                              item.recommendedAsin ===
                                                              d?.recommendedAsin
                                                            ) {
                                                              const {
                                                                exact,
                                                                ...rest
                                                              } = item;

                                                              return rest;
                                                            }
                                                            return item;
                                                          }
                                                        );

                                                      const filteredArray =
                                                        after_deleted_list.filter(
                                                          (d) =>
                                                            d?.hasOwnProperty(
                                                              "exact"
                                                            ) ||
                                                            d?.hasOwnProperty(
                                                              "expanded"
                                                            )
                                                        );

                                                      set_selected_individual_product_list(
                                                        filteredArray
                                                      );
                                                    }}
                                                  >
                                                    <i className="ki-outline ki-cross-square text-primary fs-2" />{" "}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="fw-bold">
                                              {" "}
                                              {Object.entries(d?.expanded || {})
                                                ?.length > 0 && (
                                                <div className="justify-content-end d-flex align-items-center">
                                                  {" "}
                                                  <span
                                                    className="ms-2 btn btn-icon h-35px w-35px btn-light"
                                                    onClick={() => {
                                                      const after_deleted_list =
                                                        selected_individual_product_list.map(
                                                          (item) => {
                                                            if (
                                                              item.recommendedAsin ===
                                                              d?.recommendedAsin
                                                            ) {
                                                              const {
                                                                expanded,
                                                                ...rest
                                                              } = item;
                                                              return rest;
                                                            }
                                                            return item;
                                                          }
                                                        );

                                                      const filteredArray =
                                                        after_deleted_list.filter(
                                                          (d) =>
                                                            d?.hasOwnProperty(
                                                              "exact"
                                                            ) ||
                                                            d?.hasOwnProperty(
                                                              "expanded"
                                                            )
                                                        );

                                                      set_selected_individual_product_list(
                                                        filteredArray
                                                      );
                                                    }}
                                                  >
                                                    <i className="ki-outline ki-cross-square text-primary fs-2" />
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <NoData type="new" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step5ManualProductTargeting;
