import { GetLinks } from "../../../../core";

export default [
  {
    key: "/user-accounts",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline  ki-profile-user fs-1"></i>
      </span>
    ),
    label: GetLinks("/user-accounts", "User accounts"),
  },
];
