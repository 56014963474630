import { message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { MakeApiCall } from "../../../../../../apis/make-api-call";
import { DefaultPerPage, DisplayNotification } from "../../../../../../config";
import Setp5ManualKeywordTargeting from "./setp-5-manual-keyword-targeting";
import Step5ManualProductTargeting from "./step-5-manual-product-targeting";
import { Step5Wrapper } from "./style";
const { Dragger } = Upload;
const SPStep5 = (props) => {
  const {
    campaign_data,
    selected_product_list,
    manual_sub_targeting_type,
    set_manual_sub_targeting_type,
    selected_keyword_list,
    set_selected_keyword_list,
    selected_individual_product_list,
    set_selected_individual_product_list,
    selected_categroy_list,
    set_selected_categroy_list,
    all_common_hide_show,
    set_all_common_hide_show,
    common_default_bid,
    set_common_default_bid,
    selected_currency_sign,
    selected_marketplace,
  } = props;
  const [sugg_keyword_list, set_sugg_keyword_list] = useState([]);
  const [keyword_loading, set_keyword_loading] = useState(true);
  const [sugg_product_targeting, set_sugg_product_targeting] = useState([]);
  const [product_targeting_loading, set_product_targeting_loading] =
    useState(true);
  const [sugg_category_targeting, set_sugg_category_targeting] = useState([]);
  const [category_targeting_loading, set_category_targeting_loading] =
    useState(true);
  const [
    selected_individual_product_filter_by,
    set_selected_individual_product_filter_by,
  ] = useState(["Exact"]);
  const [selected_keyword_filterby, set_selected_keyword_filterby] = useState([
    "BROAD",
    "PHRASE",
    "EXACT",
  ]);
  const [bid_selection, set_bid_selection] = useState("suggested_bid");
  const [enterlist_keyword, set_enterlist_keyword] = useState("");
  const [enterlist_product, set_enterlist_product] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [fileContent, setFileContent] = useState([]);

  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
  });

  useEffect(() => {
    const asinsList = selected_product_list?.map((d) => {
      return d?.asin1;
    });
    if (manual_sub_targeting_type === "product_targeting") {
      const productData = {
        marketplace_id: selected_marketplace,
        asins: asinsList,
        ...all_filters,
      };
      set_product_targeting_loading(true);
      GetProductTargeting(productData);

      set_category_targeting_loading(true);
      GetCategoryTargeting(productData);
    } else {
      const data = {
        marketplace_id: selected_marketplace,
        bidding_strategy: campaign_data?.bidding_strategy,
        asins: asinsList,
        sort_dimension: "DEFAULT",
      };
      set_keyword_loading(true);
      GetSuggestedKeyword(data);
    }
  }, [manual_sub_targeting_type]);

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    const asinsList = selected_product_list?.map((d) => {
      return d?.asin1;
    });
    const apiObj = {
      marketplace_id: "ATVPDKIKX0DER",
      asins: asinsList,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    set_product_targeting_loading(true);
    GetProductTargeting(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    const asinsList = selected_product_list?.map((d) => {
      return d?.asin1;
    });
    const apiObj = {
      marketplace_id: "ATVPDKIKX0DER",
      asins: asinsList,
      page: 1,
      "per-page": e,
    };
    set_product_targeting_loading(true);
    GetProductTargeting(apiObj);
  };

  const GetSuggestedKeyword = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-keyword-targeting-suggestions`,
      `POST`,
      data,
      true
    );

    if (response?.status) {
      set_keyword_loading(false);
      const transformedData = response?.data.map((item) => {
        const { bid_info, ...rest } = item;
        return { ...rest, ...bid_info };
      });
      set_sugg_keyword_list(transformedData);
      // DisplayNotification("success", "Success", response?.message);
    } else {
      set_keyword_loading(false);
      set_sugg_keyword_list([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetProductTargeting = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-product-targeting-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      set_product_targeting_loading(false);
      setTotalPage(response?.data?.pagination?.totalCount);
      set_sugg_product_targeting(response?.data?.records);
      // DisplayNotification("success", "Success", response?.message);
    } else {
      set_product_targeting_loading(false);
      set_sugg_product_targeting([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetCategoryTargeting = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-categories-targeting-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      set_category_targeting_loading(false);
      set_sugg_category_targeting(response?.data);
      // DisplayNotification("success", "Success", response?.message);
    } else {
      set_category_targeting_loading(false);
      set_sugg_category_targeting([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const handleUploadKeywordFile = (info) => {
    if (info.file.status === "done") {
      const file = info.file.originFileObj;

      // Create a FileReader to read the file content
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Get the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData.length === 0) {
          message.warning("No data found in the file.");
          return;
        }

        // Extract headers and rows
        const headers = jsonData[0];
        const rows = jsonData.slice(1);
        console.log(rows, "rows");
        rows?.map((d) => {
          console.log(d, "-d");
          const match_type = d?.[1]?.toLowerCase();
          const newObj = {
            keyword_name: d?.[0],
            [match_type]: {
              keyword_name: d?.[0],
              name: match_type?.toUpperCase(),
              old_sugg_bid: d?.[2],
              sugg_bid: d?.[2],
              state: "ENABLED",
            },
          };

          set_selected_keyword_list((prev) => {
            const existingKeyword = prev?.find(
              (sk) => sk?.keyword_name === d?.[0]
            );
            if (existingKeyword) {
              // Update existing keyword
              return prev.map((sk) =>
                sk?.keyword_name === d?.[0]
                  ? {
                      ...sk,
                      ...newObj,
                    }
                  : sk
              );
            } else {
              // Add new keyword
              return [...prev, newObj];
            }
          });
        });
        DisplayNotification(
          "success",
          "Success",
          "File uploaded and data processed successfully!"
        );
      };

      reader.onerror = () => {
        DisplayNotification("error", "Error", "Error reading file.");
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleUploadProductsFile = (info) => {
    if (info.file.status === "done") {
      const file = info.file.originFileObj;

      // Create a FileReader to read the file content
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Get the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData.length === 0) {
          message.warning("No data found in the file.");
          return;
        }

        // Extract headers and rows
        const rows = jsonData.slice(1);
        console.log(rows, "rows");
        rows?.map((d) => {
          console.log(d, "-d");
          const type = d?.[1]?.toLowerCase();
          const newObj = {
            recommendedAsin: d?.[0],
            suggested_bid: d?.[2],
            [type]: {
              suggested_bid: d?.[2],
              state: "ENABLED",
              expression_type:
                type === "expanded" ? "ASIN_EXPANDED_FROM" : "ASIN_SAME_AS",
              expression_value: d?.[0],
            },
          };

          set_selected_individual_product_list((prev) => {
            const existingASIN = prev?.find(
              (es) => es?.recommendedAsin === d?.[0]
            );
            if (existingASIN) {
              return prev.map((sk) =>
                sk?.recommendedAsin === d?.[0]
                  ? {
                      ...sk,
                      ...newObj,
                    }
                  : sk
              );
            } else {
              return [...prev, newObj];
            }
          });
        });
        DisplayNotification(
          "success",
          "Success",
          "File uploaded and data processed successfully!"
        );
      };

      reader.onerror = () => {
        DisplayNotification("error", "Error", "Error reading file.");
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const AddAllKeywords = () => {
    const data = sugg_keyword_list?.map((d) => {
      return {
        keyword_name: d?.keyword,
        broad: {
          keyword_name: d?.keyword,
          name: "BROAD",
          old_sugg_bid: d?.BROAD?.suggested_bid || 0,
          sugg_bid:
            bid_selection === "suggested_bid"
              ? d?.BROAD?.suggested_bid
              : common_default_bid,
          state: "ENABLED",
        },
        phrase: {
          keyword_name: d?.keyword,
          name: "PHRASE",
          old_sugg_bid: d?.PHRASE?.suggested_bid || 0,
          sugg_bid:
            bid_selection === "suggested_bid"
              ? d?.PHRASE?.suggested_bid
              : common_default_bid,
          state: "ENABLED",
        },
        exact: {
          keyword_name: d?.keyword,
          name: "EXACT",
          old_sugg_bid: d?.EXACT?.suggested_bid || 0,
          sugg_bid:
            bid_selection === "suggested_bid"
              ? d?.EXACT?.suggested_bid
              : common_default_bid,
          state: "ENABLED",
        },
      };
    });

    set_selected_keyword_list(data);
  };
  const CheckProductTargetingByAsin = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-targeting-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      const data = response?.data?.records?.map((d) => {
        const isExact =
          selected_individual_product_filter_by?.includes("Exact");
        const isExpanded =
          selected_individual_product_filter_by?.includes("Expanded");

        const newObj = {
          recommendedAsin: d?.asin,
          suggested_bid:
            bid_selection === "suggested_bid"
              ? d?.suggested_bid || 0
              : common_default_bid,
          image_url: d?.image_url,
          product_name: d?.product_name,
          item_price: d?.item_price,
          ...(isExact && {
            exact: {
              suggested_bid:
                bid_selection === "suggested_bid"
                  ? d?.suggested_bid || 0
                  : common_default_bid,
              state: "ENABLED",
              expression_type: "ASIN_SAME_AS",
              expression_value: d?.asin,
            },
          }),
          ...(isExpanded && {
            expanded: {
              suggested_bid:
                bid_selection === "suggested_bid"
                  ? d?.suggested_bid || 0
                  : common_default_bid,
              state: "ENABLED",
              expression_type: "ASIN_EXPANDED_FROM",
              expression_value: d?.asin,
            },
          }),
        };

        set_selected_individual_product_list((prev) => {
          const existingASIN = prev?.find(
            (es) => es?.recommendedAsin === d?.asin
          );
          if (existingASIN) {
            return prev.map((sk) =>
              sk?.recommendedAsin === d?.asin
                ? {
                    ...sk,
                    ...newObj,
                  }
                : sk
            );
          } else {
            return [...prev, newObj];
          }
        });
      });

      // selected_individual_product_list.push(...response?.data?.records);
      // set_selected_individual_product_list(selected_individual_product_list);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <Step5Wrapper>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className>
          {/* <div
            className="row align-items-center mb-5 py-5 mx-0 px-0"
            style={{
              background: "#fff",
              border: "1px solid #e8e8ed",
              borderRadius: "5px",
            }}
          >
            <div className="col-md-3">
              <h5 className="mb-0">Manual Targeting:</h5>
            </div>
            <div className="col-md-9">
              <div className="d-flex gap-4 align-items-center">
                <Radio.Group
                  name="radiogroup"
                  onChange={(e) => {
                    set_manual_sub_targeting_type(e.target.value);
                  }}
                  value={manual_sub_targeting_type}
                >
                  <Radio value={"keyword_targeting"}>
                    <span className="d-flex flex-column">
                      <span className="fw-bold fs-6">Keyword Targeting</span>
                    </span>
                  </Radio>
                  <Radio value={"product_targeting"}>
                    <span className="d-flex flex-column">
                      <span className="fw-bold fs-6">Product Targeting</span>
                    </span>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div> */}
          {/* <div className="row g-5 align-items-center mt-0">
            <div className="col-md-9 ms-auto ">
              <div className="automaticDiv1" style={{ display: "block" }}>
                <p className="fs-6">
                  Choose keywords to help your products appear in shopper
                  searches.
                </p>
              </div>
              <div className="manualDiv1" style={{ display: "none" }}>
                <p className="fs-6">
                  Choose specific products, categories, brands, or other product
                  features to target your ads.
                </p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="separator separator-dashed border-gray-300" />
        {manual_sub_targeting_type === "keyword_targeting" && (
          <Setp5ManualKeywordTargeting
            set_all_common_hide_show={set_all_common_hide_show}
            all_common_hide_show={all_common_hide_show}
            bid_selection={bid_selection}
            set_bid_selection={set_bid_selection}
            common_default_bid={common_default_bid}
            set_common_default_bid={set_common_default_bid}
            keyword_loading={keyword_loading}
            sugg_keyword_list={sugg_keyword_list}
            selected_keyword_list={selected_keyword_list}
            set_selected_keyword_list={set_selected_keyword_list}
            selected_keyword_filterby={selected_keyword_filterby}
            set_selected_keyword_filterby={set_selected_keyword_filterby}
            enterlist_keyword={enterlist_keyword}
            set_enterlist_keyword={set_enterlist_keyword}
            AddAllKeywords={AddAllKeywords}
            handleUploadKeywordFile={handleUploadKeywordFile}
            isUploading={isUploading}
            selected_currency_sign={selected_currency_sign}
          />
        )}
        {manual_sub_targeting_type === "product_targeting" && (
          <Step5ManualProductTargeting
            all_common_hide_show={all_common_hide_show}
            set_all_common_hide_show={set_all_common_hide_show}
            bid_selection={bid_selection}
            set_bid_selection={set_bid_selection}
            common_default_bid={common_default_bid}
            set_common_default_bid={set_common_default_bid}
            category_targeting_loading={category_targeting_loading}
            sugg_category_targeting={sugg_category_targeting}
            selected_categroy_list={selected_categroy_list}
            set_selected_categroy_list={set_selected_categroy_list}
            selected_individual_product_filter_by={
              selected_individual_product_filter_by
            }
            set_selected_individual_product_filter_by={
              set_selected_individual_product_filter_by
            }
            product_targeting_loading={product_targeting_loading}
            sugg_product_targeting={sugg_product_targeting}
            selected_individual_product_list={selected_individual_product_list}
            set_selected_individual_product_list={
              set_selected_individual_product_list
            }
            all_filters={all_filters}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
            enterlist_product={enterlist_product}
            set_enterlist_product={set_enterlist_product}
            CheckProductTargetingByAsin={CheckProductTargetingByAsin}
            handleUploadProductsFile={handleUploadProductsFile}
            isUploading={isUploading}
            selected_currency_sign={selected_currency_sign}
            selected_marketplace={selected_marketplace}
          />
        )}
      </div>
    </Step5Wrapper>
  );
};

export default SPStep5;
